<template>
  <div class="min-h-screen">
    <div
      class="w-11/12 2xl:w-4/6 mx-auto relative bg-gray-50 m-4 p-8 rounded-md border shadow-lg"
    >
      <h1 class="text-left py-2 text-3xl font-semibold text-gray-600">
        Preview
      </h1>
      <form
        @submit.prevent="Submitcourse"
        class="flex flex-col gap-8"
        v-if="form"
      >
        <!-- step1 -->
        <div>
          <p>Step 1</p>
          <div class="">
            <label for="category" class="flex gap-2 items-center">
              <div>Category<span class="text-red-400">*</span></div>
            </label>
            <input
              id="category"
              type="text"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
              v-model="form.category"
              readonly
            />
          </div>

          <!-- <div class="">
            <label for="batch">
              Batch<span class="text-red-400">*</span>
            </label>
            <input
              id="batch"
              type="text"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
              v-model="form.batch"
              readonly
            />
          </div> -->
          <div class="">
            <label for="code"> Code<span class="text-red-400">*</span> </label>
            <input
              id="code"
              type="text"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
              v-model="form.code"
              readonly
            />
          </div>
        </div>
        <!-- step2 -->
        <div>
          <p>Step 2</p>
          <div>
            <label for="domain"
              >Domain<span class="text-red-400">*</span></label
            >
            <input
              id="domain"
              type="text"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
              v-model="form.domain"
              readonly
            />
          </div>
          <div>
            <label for="title">Title<span class="text-red-400">*</span></label>
            <input
              id="title"
              type="text"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
              v-model="form.title"
              readonly
            />
          </div>
        </div>
        <!-- step3 -->
        <div>
          <p>Step 3</p>
          <div>
            <label for="units"
              >Unit title<span class="text-red-400">*</span></label
            >
            <input
              v-model="form.unit_title"
              id="unit title"
              type="text"
              placeholder="unit title"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
              readonly
            />
          </div>
          <div>
            <label for="units"
              >Unit name<span class="text-red-400">*</span></label
            >
            <input
              id="units"
              type="text"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
              v-model="form.units"
              readonly
            />
          </div>
          <div>
            <label for="size"
              >Unit size<span class="text-red-400">*</span></label
            >
            <input
              id="size"
              type="number"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
              v-model="form.size"
              readonly
            />
          </div>

          <div>
            <label for="unit_instruction"
              >Instruction promt<span class="text-red-400">*</span></label
            >

            <textarea
              name=""
              readonly
              v-model="form.unit_instruction"
              id=""
              cols="20"
              rows="1"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
            >
            </textarea>
          </div>
          <div class="flex justify-start items-center gap-2">
            <label for="freeCourse">Assignment </label>
            <input
              id="Assessments"
              type="checkbox"
              class=""
              v-model="form.AssignDatastatus"
              required
            />
          </div>
          <div>
            <label for="assessmentDetails"
              >Number of assignment<span class="text-red-400">*</span></label
            >
            <input
              id="assessmentDetails"
              type="number"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
              readonly
              v-model="form.AssignData"
              placeholder=""
            />
          </div>
          <div class="flex justify-start items-center gap-2">
            <label for="freeCourse"> Quiz </label>
            <input
              id="Quiz"
              type="checkbox"
              class=""
              v-model="form.QuizDatastatus"
              required
            />
          </div>

          <div>
            <label for="quizDetails"
              >Number of quiz<span class="text-red-400">*</span></label
            >
            <input
              id="quizDetails"
              type="number"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
              readonly
              v-model="form.QuizData"
              placeholder=""
            />
          </div>
          <div>
            <label for="instruction"
              >Course instruction promt<span class="text-red-400"
                >*</span
              ></label
            >
            <textarea
              name=""
              readonly
              v-model="form.instruction"
              id="instruction"
              cols="20"
              rows="1"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
            >
            </textarea>
          </div>
        </div>

        <div class="flex justify-between">
          <button type="button" class="gradient-btn mt-4" @click="prevPage()">
            Previous
          </button>
          <button type="submit" class="gradient-btn mt-4">Submit</button>
        </div>
      </form>

      <!-- popup -->

      <div
        v-if="popup"
        class="fixed top-0 left-0 z-50 bg-black bg-opacity-60 h-screen w-full flex items-center"
      >
        <div
          class="relative w-auto mx-auto rounded-lg bg-white p-12 text-gray-700 text-center shadow-xl"
        >
          <div
            @click="popup = false"
            class="absolute right-4 top-0 text-6xl text-right font-light primary-text-color cursor-lg cursor-pointer"
          >
            &times;
          </div>

          <h2 class="p-4 text-lg font-semibold">
            Parameters Submitted successfully !
          </h2>

          <button type="submit" class="gradient-btn mt-4" @click="generate()">
            Generate
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "NewCoursePreview",
  data() {
    return {
      form: {
        category: "",
        batch: "",
        code: "",
        domain: "",
        title: "",
        units: "",
        size: "",
        unit_title: "",
        unit_instruction: "",
        AssignDatastatus: "",
        AssignData: "",
        QuizDatastatus: "",
        QuizData: "",
        instruction: "",
      },
      popup: false,
      newcourseid: [],
      gemini_id: "",
      genaraic: [],
    };
  },
  computed: mapGetters(["userInfo", "instructorInfo"]),

  // created() {
  //   if (this.$route.query) {
  //     this.form = this.$route.query;
  //   } else {
  //     console.error("Form data is missing from the route params.");
  //   }
  //   console.log("get data.....", this.$route.query);
  //   console.log("userinfo",this.userInfo.token_key)
  // },
  mounted() {
    const storedFormData = localStorage.getItem("formatData");
    console.log("storedFormData///////", storedFormData);
    if (storedFormData) {
      this.form = JSON.parse(storedFormData);
      localStorage.removeItem("formatData");
      console.log(" this.form///////", this.form);
    }
  },

  methods: {
    prevPage() {
      this.$router.push({ name: "NewCourse" });
    },
    async Submitcourse() {
      const formData = {
        category: this.form.category,
        batch: 1,
        code: this.form.code,
        domain: this.form.domain,
        title: this.form.title,
        unit_unit: this.form.units,
        unit_size: this.form.size,
        unit_title: this.form.unit_title,
        unit_instruction: this.form.unit_instruction,
        Assignment: this.form.AssignDatastatus,
        Assignment_size: this.form.AssignData,
        quiz: this.form.QuizDatastatus,
        quiz_size: this.form.QuizData,
        instruction: this.form.instruction,
      };
      console.log("formdata", formData);
      console.log("form.AssignDatastatus", this.form.AssignDatastatus);
      console.log("userinfo",this.userInfo.token_key)
      await axios
        .post(
          `${process.env.VUE_APP_API}/coursepromptnew/?Key=${this.userInfo.token_key}`,
          formData
        )

        .then((response) => {
          if (response.data.status == 200) {
            this.newcourseid = response.data.data;
            this.gemini_id = response.data.data.id;
            console.log("getid//////", this.newcourseid.id);
            console.log("this.gemini_id 323", this.gemini_id);
            console.log(" Course with AI", response.data);
            this.popup = true;
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    async generate() {
      console.log("gemini_id", this.gemini_id);
      await axios
        .get(
          `${process.env.VUE_APP_API}/gemini/post/${this.gemini_id}/?Key=${this.userInfo.token_key}`
        )
        .then((resp) => {
          console.log("response", resp);
          if (resp.status === 200) {
            this.genaraic = resp.data;
            console.log("genaricget........", this.genaraic);

            this.$router.push({
              name: "instructorCourse",
              query: { activeTab: "coursepromt" },
            });
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    // generate() {
    //   console.log("userinfo",this.userInfo.token_key);
    //   console.log("generate 340//////",this.gemini_id);
    //   const get_gemini_id=this.gemini_id;
    //   console.log("get_gemini_id",get_gemini_id)
    //   axios
    //     .get(`${process.env.VUE_APP_API}/gemini/post/${get_gemini_id}/?Key=${this.userInfo.token_key}`)
    //      .then((resp) => {
    //         if (resp.data.status == 200) {
    //           this.genaraic = resp.data;
    //           console.log("genaricdata", this.genaraic)

    //         }
    //       })
    //       .catch((err) => {
    //         console.log("error", err);
    //       });
    //   this.$router.push({ name: "instructorCourse",query: { activeTab: 'coursepromt' } });

    // },
  },
};
</script>

<style></style>
