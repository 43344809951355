<template>
<div class="page-wrapper relative">
    <Navigation />
    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
    <confirmPopup v-if='popuplessonDelete==true' :title="titlepass" @cancelClick="popuplessonDelete=false" @confirmClick="deleteLesson(idpass)" />
    <confirmPopup v-if='popupsectionDelete==true' :title="titlepass" @cancelClick="popupsectionDelete=false" @confirmClick="deleteSection(idpass)" />
    <confirmPopup v-if='popupDocumentDelete==true' :title="titlepass" @cancelClick="popupDocumentDelete=false" @confirmClick="deleteDocument(idpass)" />
    <confirmPopup v-if='popupDocumentDelete==true' :title="titlepass" @cancelClick="popupDocumentDelete=false" @confirmClick="deleteTimeTable(idpass)" />
    <div class="min-h-screen max-w-7xl mx-auto">
        <!-- <div class="flex">
        <textarea  v-model="markdown" name="" id="" rows="10" @input="update"></textarea>
        <markdown-preview :source="markdown"></markdown-preview>
  </div> -->
        <!-- {{this.lessonIn fo}} -->
        <div class="relative w-full h-20 py-3">
            <p class="font-bold text-2xl text-gray-700 w-full ">Section & Lesson</p> 
            <router-link :to="{name:'preview',params:{courseid:this.courseId}}" class="absolute top-4 right-6">
                <button class="mx-32 my-5 bg-white border px-2 py-0.5 text-sm text-black w-24 rounded-lg hover:text-blue-900">Preview</button>
            </router-link>


            <div @click="$router.go(-1)" class="absolute right-8 top-8">
                <div class='mb-1 cursor-pointer rounded-lg flex border justify-center w-24 gap-2 items-center py-1 bg-white'>
                    <div class=" text-sm  text-gray-700 hover:text-teal-500 " title='back'>Back </div>
                    <div class="flex items-center">
                        <img src="https://img.icons8.com/external-simple-solid-edt.graphics/15/undefined/external-Back-arrows-simple-solid-edt.graphics-2.png" />
                    </div>
                    <!-- <img class='h-5 w-5 md:h-8 md:w-8' src="../../assets/closebutton.svg" /> -->
                </div>
            </div>
        </div>

        <div v-if="sectionInfo.count > 0" class="border rounded-t-md rounded-b-md w-full  overflow-hidden mb-4 m-4 ">
            <div v-for="section in sectionInfo.data" :key="section">
                <div class="flex items-center gap-2 py-2 border-b bg-gray-100 w-full px-3">
                    <p class="text-md md:text-xl text-gray-700">{{section.title}}</p>
                    <img @click="sectionval=section;popUpsectionUpdate=true" title="Edit" class="h-5 w-5 md:h-6 md:w-6 cursor-pointer" src="../assets/edit.svg" alt="">
                    <img @click="titlepass=section.title;popupsectionDelete=true;idpass=section.id" class="h-5 w-5 md:h-6 md:w-6 cursor-pointer" src="../assets/trash.svg" alt="">
                </div>
                <div v-if="lessonInfo != null">
                    <div v-for="lesson in lessonInfo.results" :key="lesson" class="text-gray-600">
                        <p v-if="section.id == lesson.section_id" class="text-xs md:text-lg border-b py-2 hover:bg-gray-100 pl-3 md:pl-8">
                            {{lesson.title}}
                            <span @click="titlepass=lesson.title;popuplessonDelete=true;idpass=lesson.id" class="mx-2 text-gray-500 bg-white border border-gray-400 hover:bg-red-600 hover:border-red-600 hover:text-white p-0.5 px-1 md:px-2 inline-block float-right text-xs rounded cursor-pointer"> Delete</span>
                            <span @click="lessonVal=lesson;popUp=true" class="mx-2 text-teal-600 bg-white border border-teal-600 hover:bg-teal-600 hover:text-white p-0.5 px-1 md:px-2 inline-block float-right text-xs rounded cursor-pointer">Edit</span>
                        </p>
                    </div>
                </div>
                <p v-else class="text-md border-b py-2 hover:bg-gray-100 pl-8 ">
                    No lesson added
                </p>
            </div>
        </div>
        <div v-else class="px-4">
            <p class="text-md  py-2">
                No section added yet </p>
        </div>
        <div>
            <p class="text-black">Documents</p>
            <div v-if="this.documentList != ''"> 
            <div class="flex flex-col gap-2 border ml-4">
                <div v-for="file in this.documentList" :key='file' class="text-xs md:text-lg border-b py-2 hover:bg-gray-100 pl-3 md:pl-8 ">
                    <a :href="file.resource_file" target="_blank">{{file.name}}</a>
                    <span @click="titlepass=file.name;popupDocumentDelete=true;idpass=file.id" class="mx-2 text-gray-500 bg-white border border-gray-400 hover:bg-red-600 hover:border-red-600 hover:text-white p-0.5 px-1 md:px-2 inline-block float-right text-xs rounded cursor-pointer"> Delete</span>
                </div>
            </div>
        </div>
        <p v-else class="text-md border-b py-2 hover:bg-gray-100 pl-8 ">
            No Documents added
        </p>

        </div>
        <div>
            <p class="text-black">Time Table</p>
            <div v-if="this.timeTable != ''">
                <div class="flex flex-col gap-2 border ml-4">
                    <div class="text-xs md:text-lg border-b py-2 hover:bg-gray-100 pl-3 md:pl-8 ">
                        <a :href="this.timeTable.resource_file" target="_blank">{{this.timeTable_val}}</a>
                        <span @click="titlepass=this.timeTable.resource_file;popupDocumentDelete=true;idpass=this.timeTable.id" class="mx-2 text-gray-500 bg-white border border-gray-400 hover:bg-red-600 hover:border-red-600 hover:text-white p-0.5 px-1 md:px-2 inline-block float-right text-xs rounded cursor-pointer"> Delete</span>
                        <span @click="TimeTableVal=this.timeTable;TimeTablepopUp=true" class="mx-2 text-teal-600 bg-white border border-teal-600 hover:bg-teal-600 hover:text-white p-0.5 px-1 md:px-2 inline-block float-right text-xs rounded cursor-pointer">Edit</span>
                    </div>
                </div>
            </div>
                <p v-else class="text-md border-b py-2 hover:bg-gray-100 pl-8 ">
                    No Time Table added
                </p>
        </div>        
        <div class="my-4">
            <button @click="popUpsection=true" class="gradient-btn ml-4">
                Add Unit
            </button>
            <button v-if="sectionInfo.count > 0" @click="popUpLesson=true" class="gradient-btn ml-2 ">
                Add Lesson
            </button>
            <button @click="is_documentPopup=true" class="gradient-btn ml-2">
                Add Documents
            </button>
            <button v-if="timeTable_val==''" @click="is_tablePopup=true" class="gradient-btn ml-2">
                Add Time Table
            </button>            
        </div>
        <!-- Add new Section -->
        <div v-if="popUpsection" class="w-full h-full mx-auto fixed top-0 left-0 bg-black bg-opacity-75 p-4 flex items-center" style="z-index:5000">
            <form ref="addSection" @submit.prevent="createNewsection" class="w-full lg:max-w-4xl 2xl:max-w-5xl mx-auto bg-gray-100 relative p-6 flex flex-col gap-4 rounded-md">
                <div @click="popUpsection=false" class="absolute top-6 right-6 border cursor-pointer flex rounded-lg bg-white z-50">
                    <p class="px-1 md:px-2 py-1 text-sm md:text-base hover:text-red-500">Close</p>
                    <img class='h-5 w-5 md:h-8 md:w-8' src="../assets/closebutton.svg" />
                </div>
                <p class="text-xl mt-5 ">Add New Unit</p>
                <div class="w-full flex flex-col gap-2">
                    <label for="title">
                        Title
                    </label>
                    <input id="title" type="text" class="border border-gray-300 rounded-md w-full p-2" required>
                </div>
                <button type="submit" class="gradient-btn mt-4 w-44">
                    Add
                </button>
            </form>
        </div>
        <!-- Update Sections -->
        <div v-if="popUpsectionUpdate" class="w-full h-full mx-auto fixed top-0 left-0 bg-black bg-opacity-75 p-4 flex items-center" style="z-index:5000">
            <form @submit.prevent="updatesection(sectionval)" class="w-full lg:max-w-4xl 2xl:max-w-5xl mx-auto bg-gray-100 relative p-6 flex flex-col gap-4 rounded-md">
                <div @click="popUpsectionUpdate=false" class="absolute top-6 right-6 border cursor-pointer flex rounded-lg bg-white z-50">
                    <p class="px-1 md:px-2 py-1 text-sm md:text-base hover:text-red-500 ">Close</p>
                    <img class='h-5 w-5 md:h-8 md:w-8' src="../assets/closebutton.svg" />
                </div>
                <p class="text-xl py-4">Update Unit</p>
                <div class="w-full flex flex-col gap-2">
                    <label for="title">
                        Title
                    </label>
                    <input id="title" v-model="sectionval.title" type="text" class="border border-gray-300 rounded-md w-full p-2" required>
                </div>
                <button type="submit" class="gradient-btn mt-4 w-44">
                    Update
                </button>

            </form>
        </div>

        <!-- add new lesson -->
        <div v-if="popUpLesson" class="w-full h-full mx-auto fixed top-0 left-0 bg-black bg-opacity-75 z-50 p-4 flex flex-col justify-center" style="z-index:5000">
            <!-- Unit name -->
            <div class="w-full 2xl:max-w-7xl mx-auto overflow-y-auto" style="height:95%;scrollbar-color:gray lightgray">
                <form ref="lessonaddFn" @submit.prevent="createNewLesson" class="relative bg-gray-100 flex flex-col gap-4 w-full p-6 rounded-md">
                    <div @click="popUpLesson=false" class="absolute top-6 hover:bg-gray-100 right-6 border cursor-pointer flex bg-white rounded-lg z-50">
                        <p class="px-1 md:px-2 py-1 text-sm md:text-base hover:text-red-500">Close</p>
                        <img class='h-5 w-5 md:h-8 md:w-8' src="../assets/closebutton.svg" />
                    </div>
                    <p class='font-semibold text-base md:text-lg 2xl:text-2xl'>Add New Lesson</p>
                    <div class="">
                        <label for="section">
                            Unit
                        </label>
                        <select name="section" class="bg-gray-50 border-gray-300 rounded-md p-2 block w-full" id="section">
                            <option v-for="section in sectionInfo.data" :key="section" :value="section.id">
                                {{section.title}}
                            </option>
                        </select>
                    </div>

                    <!-- Title -->
                    <div class="">
                        <label for="title">
                            Title <span class="text-red-400">*</span>
                        </label>
                        <input id="title" type="text" class="border border-gray-300 rounded-md w-full p-2" required>
                    </div>

                    <div class="flex gap-2">
                        <!-- duration -->
                        <div class="w-1/3">
                            <label for="duration">
                                Duration <span class="text-red-400">*</span>
                                <br> <small class="text-gray-500">(Enter minutes only like 5 or 30 or 90 )</small>
                            </label>
                            <input id="duration" type="text" class="border border-gray-300 rounded-md w-full p-2" required>
                        </div>

                        <!-- Video Url -->
                        <div class="w-1/3">
                            <label for="videoUrl">
                                video Url
                                <br> <small class="text-gray-500">Source url of the video</small>
                            </label>
                            <input id="videoUrl" type="text" class="border border-gray-300 rounded-md w-full p-2">
                        </div>
                        <!-- Order By -->
                        <div class="w-1/3">
                            <label for="orderby">
                                Order By <span class="text-red-400">*</span>
                                <br> <small class="text-gray-500">Lesson Position</small>
                            </label>
                            <input id="orderby" type="number" class="border border-gray-300 rounded-md w-full p-2" required>
                        </div>
                    </div>

                    <!-- Content -->
                    <div class="">
                        <div class="col-start-1 col-end-4">
                            <label for="content">
                                Content <span class="text-red-400">*</span>
                            </label>
                            <MarkdownEditor class=" rounded-md" />
                        </div>
                    </div>

                    <!-- keywords -->
                    <div class="">
                        <label for="keywords">
                            Keywords
                            <br><small class="text-gray-500">comma ( , ) separated values</small>
                        </label>
                        <textarea id="keywords" maxlength="200" class="border border-gray-300 rounded-md w-full p-2" placeholder="keyword1, keyword2, keyword3, ..."></textarea>
                    </div>
                    <div class="">
                        <label for="pdffile">
                            File
                        </label>
                        <input id="pdffile" type="file" maxlength="200" class="border border-gray-300 rounded-md p-2">
                    </div>
                    <button type="submit" class="gradient-btn w-44">
                        Add
                    </button>
                </form>
            </div>
        </div>

        <!-- Update lesson -->
        <div v-if="popUp" class="w-full h-full fixed top-0 right-0 bg-black bg-opacity-75 z-50 p-4 flex items-center" style="z-index:5000">
            <form @submit.prevent="updateLesson" class="relative w-full 2xl:max-w-7xl mx-auto bg-gray-100 p-6 rounded-md flex flex-col gap-4 overflow-y-auto" style="height:98%;scrollbar-color:gray lightgray">
                <div @click="popUp=false" class="mb-1 absolute top-6 right-6  border cursor-pointer hover:bg-gray-100 flex rounded-lg bg-white z-50">
                    <p class="px-2 py-1 text-sm md:text-base hover:text-red-500">Close</p>
                    <img class='h-5 w-5 md:h-8 md:w-8' src="../assets/closebutton.svg" />
                </div>
                <p class="text-base md:text-xl 2xl:text-2xl">Update Lesson</p>
                <div class="flex flex-col gap-2">
                    <label for="Title">Title <span class="text-red-400">*</span></label>
                    <input type="text" v-model="lessonVal.title" class="border border-gray-300 rounded-md w-1/2" required>
                </div>
                <div class="flex justify-between gap-4">

                    <div class="flex flex-col gap-2 w-1/2">
                        <label for="duration">Duration <span class="text-red-400">*</span></label>
                        <input type="text" v-model="lessonVal.duration" class="border border-gray-300 rounded-md" required>
                    </div>
                    <div class="w-1/2">
                        <label for="section">
                            Section
                        </label>
                        <select name="section" class="bg-gray-50 border-gray-300 rounded-md p-2 block w-full" id="section" v-model="lessonVal.section_id">
                            <option v-for="section in sectionInfo.data" :key="section" :value="section.id">
                                {{section.title}}
                            </option>
                        </select>
                    </div>

                </div>
                <div class="flex justify-between gap-4">
                    <div class="flex flex-col gap-2 w-3/6">
                        <label for="video_url">Video_url</label>
                        <input type="text" v-model="lessonVal.video_url" class="border border-gray-300 rounded-md">
                    </div>
                    <div class="flex flex-col gap-2 w-3/6">
                        <label for="orderby">Order By</label>
                        <input type="text" v-model="lessonVal.orderby" class="border border-gray-300 rounded-md">
                    </div>
                </div>
                <div class="flex flex-col gap-2 w-full">
                    <label for="description">Content <span class="text-red-400">*</span></label>
                    <MarkdownEditor class="rounded-md w-full bg-red-200" :markvalues="lessonVal.content" />
                </div>
                <div class="flex flex-col gap-2">
                    <label for="keyworeds">keyworeds</label>
                    <input type="text" v-model="lessonVal.keyworeds" class="border border-gray-300 rounded-md w-1/2">
                </div>
                <div class="">
                    <label for="pdffile">
                        File
                    </label>
                    <input id="pdffile" type="file" maxlength="200" class="border border-gray-300 rounded-md p-2">
                </div>
                <div class="flex items-center gap-4 ">
                    <label for="keyworeds">Status</label>
                    <input type="checkbox" v-model="lessonVal.status" class="border border-gray-300 rounded-md  px-2">
                </div>

                <button class="gradient-btn inline rounded cursor-pointer w-44">Update</button>
            </form>
        </div>
    </div>

    <!-- File Upload -->
    <section v-if="is_documentPopup" id="app" class="bg-white fixed top-0 w-full h-full flex flex-col justify-center z-50">

        <div class="border w-full max-w-4xl mx-auto p-4 card h-96 flex flex-col gap-1 justify-center">
            <h4 class="mx-auto mb-0">Upload Reference Files</h4>
            <div @click="is_documentPopup=false" class="mb-1 absolute top-6 right-6  border cursor-pointer hover:bg-gray-100 flex rounded-lg bg-white z-50">
                <p class="px-2 py-1 text-sm md:text-base hover:text-red-500">Close</p>
                <img class='h-5 w-5 md:h-8 md:w-8' src="../assets/closebutton.svg" />
            </div>
            <form ref="fileUploadref" @submit.prevent="uploadFiles" class="flex flex-col gap-2">
                <div class="flex flex-col gap-2">
                    <label for="document_name" class="text-gray-600">Name of the Document</label>
                    <input type="text" id="document_name" class="border border-gray-300 rounded-md w-5/6">
                </div>
                <div class="flex flex-col gap-2">
                    <label for="document_file" class="text-gray-600">Choose files</label>
                    <input type="file" id="document_file">
                </div>
                <div class="flex gap-4 items-center">
                    <label for="downloadable" class="text-gray-600">Downloadable</label>
                    <input type="checkbox" id="downloadable">
                </div>
                <div class="flex justify-between">
                    <button type="submit" class="gradient-btn inline rounded cursor-pointer w-44">Submit</button>
                    <!-- <button class="gradient-btn inline rounded cursor-pointer w-44">Add More</button> -->
                </div>
            </form>
        </div>
    </section>



    <!-- Time Table Creation -->
    <section v-if="is_tablePopup" id="app" class="bg-white fixed top-0 w-full h-full flex flex-col justify-center z-50">

        <div class="border w-full max-w-4xl mx-auto p-4 card h-96 flex flex-col gap-1 justify-center">
            <h4 class="mx-auto mb-0">Time Table Files</h4>
            <div @click="is_tablePopup=false" class="mb-1 absolute top-6 right-6  border cursor-pointer hover:bg-gray-100 flex rounded-lg bg-white z-50">
                <p class="px-2 py-1 text-sm md:text-base hover:text-red-500">Close</p>
                <img class='h-5 w-5 md:h-8 md:w-8' src="../assets/closebutton.svg" />
            </div>
            <form ref="fileUploadref2" @submit.prevent="creationtimetableuploadFiles" class="flex flex-col gap-2">
                <div class="flex flex-col gap-2">
                    <label for="document_filedata" class="text-gray-600">Choose files</label>
                    <input type="file" id="document_filedata">
                </div>
                <div class="flex gap-4 items-center">
                    <label for="downloadable" class="text-gray-600">Downloadable</label>
                    <input type="checkbox" id="downloadable">
                </div>
                <div class="flex justify-between">
                    <button type="submit" class="gradient-btn inline rounded cursor-pointer w-44">Submit</button>
                    <!-- <button class="gradient-btn inline rounded cursor-pointer w-44">Add More</button> -->
                </div>
            </form>
        </div>
    </section>

        <!-- Time Table Updated (Edit) -->
    <div v-if="TimeTablepopUp" class="w-full h-full fixed top-0 right-0 bg-black bg-opacity-75 z-50 p-4 flex items-center" style="z-index:5000">

        
        <!-- <section v-if="is_tablePopup" id="app" class="bg-white fixed top-0 w-full h-full flex flex-col justify-center z-50"> -->

            <div class="border w-full max-w-4xl mx-auto p-4 card h-96 flex flex-col gap-1 justify-center">
                <h4 class="mx-auto mb-0">Time Table Files</h4>
                <div @click="TimeTablepopUp=false" class="mb-1 absolute top-6 right-6  border cursor-pointer hover:bg-gray-100 flex rounded-lg bg-white z-50">
                    <p class="px-2 py-1 text-sm md:text-base hover:text-red-500">Close</p>
                    <img class='h-5 w-5 md:h-8 md:w-8' src="../assets/closebutton.svg" />
                </div>
                
                <form ref="fileUploadref3" @submit.prevent="updatedtimetableFiles" class="flex flex-col gap-2">
                    <div class="flex flex-col gap-2">
                        <label for="document_filedata" class="text-gray-600">Choose files</label>
                        <input type="file" id="document_filedata">
                    </div>
                    <div class="flex gap-4 items-center">
                        <label for="downloadable" class="text-gray-600">Downloadable</label>
                        <input type="checkbox" v-model="TimeTableVal.download" id="checkboxdable">
                    </div>
                    <div class="flex justify-between">
                        <button type="submit" class="gradient-btn inline rounded cursor-pointer w-44">Submit</button>
                        <!-- <button class="gradient-btn inline rounded cursor-pointer w-44">Add More</button> -->
                    </div>
                </form>
            </div>
        <!-- </section> -->
    </div>
    <!-- <div v-if="popupquiz" class="absolute top-0 bg-black bg-opacity-75 w-full">
        <Quiz :course_id="this.lesson_id" />
    </div> -->
    <Footer />
</div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";
import MarkdownPreview from '@uivjs/vue-markdown-preview';
import '@uivjs/vue-markdown-preview/markdown.css';
import Quiz from "@/components/randomQuiz.vue"
import axios from "axios";
// import MarkdownEditor from "@/components/MarkdownEditor.vue"
import MarkdownEditor from "@/components/MarkdownEditor2.vue"
import {
    mapGetters
} from "vuex"
import FlashMessage from "@/components/flashMessage.vue";
import confirmPopup from "@/components/confirmPopup.vue";

export default ({
    props: ['courseId'],
    emits: ["backToCourse"],
    data() {
        return {
            markdown: "# Hello",
            lessonInfo: "",
            lessonVal: "",
            TimeTableVal:"",
            TimeTablepopUp: false,
            popUp: false,
            popUpLesson: false,
            sectionInfo: "",
            popUpsection: false,
            sectionval: "",
            popUpsectionUpdate: false,
            messages: {
                text: "",
                type: 'null',
            },
            popupsectionDelete: false,
            idpass: "",
            popuplessonDelete: false,
            titlepass: "",
            lesson_id: "",
            popupquiz: false,
            is_documentPopup: false,
            is_tablePopup: false,
            pageX: 0,
            pageY: 0,
            uploadDragoverTracking: false,
            uploadDragoverEvent: false,
            documentList: '',
            timeTable: '',
            timeTable_val:'',
            popupDocumentDelete: false

        }
    },
    components: {
        Navigation,
        Footer,
        MarkdownPreview,
        MarkdownEditor,
        FlashMessage,
        confirmPopup,
        Quiz
    },
    computed: {
        ...mapGetters(["userInfo"]),
        uploadDisabled() {
            return this.files.length === 0;
        },
    },
    mounted() {
        this.lessoninfoGet()
        this.sectionInfoGet()
        this.documentInfo()
        this.TimetableInfo()
    },
    methods: {
        deleteDocument(val) {
            axios.delete(`${process.env.VUE_APP_API}/coures-resource/delete/${val}/`)
                .then(response => {
                    console.log(response)
                    if (response.data.message == "File deleted successfully") {
                        this.messages = {
                            text: "File Deleted successfully",
                            type: 'success',
                        }
                        setTimeout(() => {
                            this.messages = {
                                text: "",
                                type: 'null',
                            }
                            this.documentInfo()
                            this.popupDocumentDelete = false
                        }, 3000)
                    } else {
                        this.messages = {
                            text: "Event Faild",
                            type: 'error',
                        }
                    }
                })
        },
        deleteTimeTable(val) {
            // http://127.0.0.1:8000/timetableresource/update/7/
            console.log(`${process.env.VUE_APP_API}/timetableresource/update/${val}/?Key=${this.userInfo.token_key}`)
            axios.delete(`${process.env.VUE_APP_API}/timetableresource/update/${val}/?Key=${this.userInfo.token_key}`)
                .then(response => {
                    console.log(response)
                    if (response.data.status == 200) {
                        this.messages = {
                            text: "File Deleted successfully",
                            type: 'success',
                        }
                        setTimeout(() => {
                            this.messages = {
                                text: "",
                                type: 'null',
                            }
                             this.TimetableInfo()
                             this.timeTable_val=''
                            this.popupDocumentDelete = false
                        }, 3000)
                    } else {
                        this.messages = {
                            text: "Event Faild",
                            type: 'error',
                        }
                    }
                })
        },
        uploadFiles(el) {
            let urls = `${process.env.VUE_APP_API}/coures-resource/post/?Key=${this.userInfo.token_key}`;
            let HeaderVal = new FormData();
            HeaderVal.append("resource_file", el.target.elements.document_file.files[0])
            HeaderVal.append("download", el.target.elements.downloadable.checked)
            HeaderVal.append("Course", this.courseId)
            HeaderVal.append("name", el.target.elements.document_name.value)
            axios.post(urls, HeaderVal, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then(response => {
                if (response.data.message == "Resource file updated successfully") {
                    this.messages = {
                        text: "File updated successfully",
                        type: 'success',
                    }
                    setTimeout(() => {
                        this.messages = {
                            text: "",
                            type: 'null',
                        }
                        this.$refs.fileUploadref.reset()
                        this.documentInfo()
                        this.is_documentPopup = false
                    }, 3000)
                } else {
                    this.messages = {
                        text: "Event Faild",
                        type: 'error',
                    }
                }
            })

        },
        creationtimetableuploadFiles(el) {
            let urls = `${process.env.VUE_APP_API}/timetableresource/${this.courseId}/?Key=${this.userInfo.token_key}`;
            console.log("el.target.elements.document_file.files[0]",el.target.elements.document_filedata.files[0])
            console.log("dd.target.elements.downloadable.checked",el.target.elements.downloadable.checked,this.courseId)
            var HeaderVal = new FormData();
            let file_val = el.target.elements.document_filedata.files
            if(file_val.length>0){
                HeaderVal.append("resource_file", el.target.elements.document_filedata.files[0])
            }
                HeaderVal.append("download", el.target.elements.downloadable.value)
                HeaderVal.append("course", this.courseId)
            console.log("HeaderVal:",HeaderVal)

            axios.post(urls, HeaderVal, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then(response => {
                console.log("response::",response)
                if (response.data.course != "") {
                    this.messages = {
                        text: "File updated successfully",
                        type: 'success',
                    }
                    setTimeout(() => {
                        this.messages = {
                            text: "",
                            type: 'null',
                        }
                        this.$refs.fileUploadref2.reset()
                        this.TimetableInfo()
                        this.is_tablePopup = false
                    }, 3000)
                } else {
                    this.messages = {
                        text: "Event Faild",
                        type: 'error',
                    }
                }
            })

        },
        updatedtimetableFiles(el) {
            console.log("TimeTableVal09898",this.TimeTableVal)
            console.log("TimeTableVal123",this.TimeTableVal.id)
            console.log("TimeTableel::",this.TimeTableVal.download)
            console.log(`${process.env.VUE_APP_API}/timetableresource/update/${this.TimeTableVal.id}/?Key=${this.userInfo.token_key}`)
            let urls = `${process.env.VUE_APP_API}/timetableresource/update/${this.TimeTableVal.id}/?Key=${this.userInfo.token_key}`;
            // console.log("el.target.elements.document_file.files[0]",el.target.elements.document_filedata.files[0])
            // console.log("dd.target.elements.downloadable.checked",el.target.elements.downloadable.checked,this.courseId)
            var HeaderVal = new FormData();
            let file_val = el.target.elements.document_filedata.files
            console.log("file_val",file_val)
            console.log("el.target.elements.downloadable.value",el.target.elements.checkboxdable.value)
            if(file_val.length>0){
                HeaderVal.append("resource_file", el.target.elements.document_filedata.files[0])
            }
                HeaderVal.append("download", this.TimeTableVal.download)
                HeaderVal.append("course", this.TimeTableVal.courseId)
            console.log("HeaderVal:",HeaderVal)

            axios.put(urls, HeaderVal, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then(response => {
                console.log("response::",response)
                if (response.data.course != "") {
                    this.messages = {
                        text: "File updated successfully",
                        type: 'success',
                    }
                    setTimeout(() => {
                        this.messages = {
                            text: "",
                            type: 'null',
                        }
                        this.$refs.fileUploadref3.reset()
                        this.TimetableInfo()
                        this.TimeTablepopUp = false;
                    }, 3000)
                } else {
                    this.messages = {
                        text: "Event Faild",
                        type: 'error',
                    }
                    this.TimeTablepopUp = false;
                }
            })

        },        
        async lessoninfoGet() {
            let couseId = this.courseId
            await axios.get(`${process.env.VUE_APP_API}/lessons/?course_name=${couseId}`)
                .then(response => {

                    if (Object.keys(response.data) == 'error') {

                        this.lessonInfo = null
                    } else {

                        this.lessonInfo = response.data

                    }

                });
        },

        async sectionInfoGet() {
            let couseId = this.courseId
            await axios.get(`${process.env.VUE_APP_API}/section/?course_name=${couseId}`)
                .then(response => {
                    this.sectionInfo = response.data
                })
        },

        async documentInfo() {
            await axios.get(`${process.env.VUE_APP_API}/coures-resource/list/?Course=${this.courseId}`)
                .then(response => {
                    if (response.data.message == 'Have a Documents')
                        this.documentList = response.data.data
                    else {
                        this.documentList = ''
                    }
                })
        },
        
        async TimetableInfo() {
            console.log(`${process.env.VUE_APP_API}/timetableresource/${this.courseId}/?Key=${this.userInfo.token_key}&format=json`)

            await axios.get(`${process.env.VUE_APP_API}/timetableresource/${this.courseId}/?Key=${this.userInfo.token_key}&format=json`)
            .then(response => {
                console.log(response)
                if (response.data.status == 200){
                    console.log("data ::",response)
                    console.log(response.data.data)
                    this.timeTable = response.data.data
                    this.timeTable_val = response.data.data.resource_file.replace('http://127.0.0.1:8000/media/timetable/','').replace('https://lmsapi.onlinejain.com/media/timetable/','')
                    console.log("this.timeTable::",this.timeTable)
                }
                else {
                    console.log("else::",response)
                    this.timeTable = ''
                    }
                })
        },

        async updateLesson(el) {
            let urls = `${process.env.VUE_APP_API}/lesson_file_update/${this.lessonVal.id}/?Key=${this.userInfo.token_key}`;
            let HeaderVal = new FormData();
            let file_val = el.target.elements.pdffile.files
            if(file_val.length>0){
                HeaderVal.append("pdf_file", el.target.elements.pdffile.files[0])
                HeaderVal.append("title", this.lessonVal.title)
                HeaderVal.append("duration", this.lessonVal.duration)
                HeaderVal.append("video_url", this.lessonVal.video_url)
                HeaderVal.append("status", this.lessonVal.status)
                HeaderVal.append("content", el.target.elements)
                HeaderVal.append("keyworeds", this.lessonVal.keyworeds)
                HeaderVal.append("section_id", this.lessonVal.section_id)
                HeaderVal.append("orderby", this.lessonVal.orderby)

                await axios.put(urls, HeaderVal,{
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then(r=>{
                console.log(r)
                if(r.data.status==200){
                    this.messages = {
                            text: "lesson updated successfully",
                            type: 'success',
                        }
                }else{
                    this.messages = {
                            text: r.data.message,
                            type: 'error',
                        }
                }
            })

            }
            else{
                let token_key = this.userInfo.token_key
            let infoDetails = {
                "id": this.lessonVal.id,
                "title": this.lessonVal.title,
                "duration": this.lessonVal.duration,
                "video_url": this.lessonVal.video_url,
                "status": this.lessonVal.status,
                "created_at": this.lessonVal.created_at,
                "updated_at": new Date(),
                "content": el.target.elements[5].value,
                "keyworeds": this.lessonVal.keyworeds,
                "slug": this.lessonVal.slug,
                "section_id": this.lessonVal.section_id,
                "course_name": this.lessonVal.course_name,
                "orderby": this.lessonVal.orderby,
            }
            this.popUp = false;

            await axios.put(`${process.env.VUE_APP_API}/lessonupdate/${this.lessonVal.id}/?Key=${token_key}`, infoDetails)
                .then(response => {
                    if (response.data.message == "lesson updated successfully") {
                        this.messages = {
                            text: "lesson updated successfully",
                            type: 'success',
                        }
                    } else {
                        this.messages = {
                            text: "Event Faild",
                            type: 'error',
                        }
                    }
                })

            }  
                
            await this.lessoninfoGet()

            setTimeout(() => {
                this.messages = {
                    text: "",
                    type: 'null',
                }
            }, 3000)

        },
        async createNewLesson(el) {

            let urls = `${process.env.VUE_APP_API}/lessonsnew/?Key=${this.userInfo.token_key}`;
            let HeaderVal = new FormData();
            let couseId = this.courseId
            let file_val = el.target.elements.pdffile.files
            if(file_val.length>0){
                HeaderVal.append("pdf_file", el.target.elements.pdffile.files[0])
            }
                HeaderVal.append("title", el.target.elements.title.value)
                HeaderVal.append("duration", el.target.elements.duration.value)
                HeaderVal.append("video_url", el.target.elements.videoUrl.value)
                HeaderVal.append("status", true)
                HeaderVal.append("content", el.target.elements[5].value)
                HeaderVal.append("keyworeds", el.target.elements.keywords.value)
                HeaderVal.append("section_id", el.target.elements.section.value)
                HeaderVal.append("course_name", couseId)
                HeaderVal.append("orderby", el.target.elements.orderby.value)

            await axios.post(urls, HeaderVal,{
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then(response => {
                console.log(response)
                if (response.data.message == "your lesson added successfully") {
                    this.messages = {
                        text: "Lesson added successfully",
                        type: 'success',
                    }
                    this.$refs.lessonaddFn.reset()
                    this.popUpLesson = false
                    this.lessoninfoGet()
                } else {
                    this.messages = {
                        text: response.data.message,
                        type: 'error',
                    }
                }
            })

            setTimeout(() => {
                this.messages = {
                    text: "",
                    type: 'null',
                }
            }, 3000)

        },
        async createNewsection(el) {
            let newsection = {
                "title": el.target.elements.title.value,
                "course_id": this.courseId
            }
            await axios.post(`${process.env.VUE_APP_API}/sectionnew/?Key=${this.userInfo.token_key}`, newsection)
                .then(response => {
                    if (response.data.message == "your section added successfully") {
                        this.messages = {
                            text: "Section added successfully",
                            type: 'success',
                        }
                        this.$refs.addSection.reset()
                        this.popUpsection = false
                        this.sectionInfoGet()
                    } else {
                        this.messages = {
                            text: "Event Faild",
                            type: 'error',
                        }
                    }
                })

            setTimeout(() => {
                this.messages = {
                    text: "",
                    type: 'null',
                }
            }, 3000)
            // setTimeout(()=>{this.$router.go(0);},1000)

        },
        updatesection(data) {
            this.popUpsectionUpdate = false

            let updatedsection = {
                "id": data.id,
                "title": data.title,
                "course_name": data.course_name
            }

            axios.put(`${process.env.VUE_APP_API}/Sectionupdate/${data.id}/?Key=${this.userInfo.token_key}`, updatedsection)
                .then(response => {
                    if (response.data.message == "section updated successfully") {
                        this.messages = {
                            text: "section updated successfully",
                            type: 'success',
                        }
                    } else {
                        this.messages = {
                            text: "Event Faild",
                            type: 'error',
                        }
                    }
                })
            setTimeout(() => {
                this.messages = {
                    text: "",
                    type: 'null',
                }
            }, 3000)

        },
        async deleteLesson(lessonVal) {
            await axios.delete(`${process.env.VUE_APP_API}/lesson_deleted/${lessonVal}/?Key=${this.userInfo.token_key}`)
                .then(response => {
                    //  setTimeout(()=>{this.$router.go(0);},1000)
                    if (response.data.message == "This Lesson data deleted successfully") {
                        this.messages = {
                            text: "lesson Deleted successfully",
                            type: 'success',
                        }
                    } else {
                        this.messages = {
                            text: "Event Faild",
                            type: 'error',
                        }
                    }
                })
            setTimeout(() => {
                this.messages = {
                    text: "",
                    type: 'null',
                }
            }, 3000)

            this.popuplessonDelete = false;
            await this.lessoninfoGet()

        },
        async deleteSection(section) {

            await axios.delete(`${process.env.VUE_APP_API}/section_deleted/${section}/?Key=${this.userInfo.token_key}`)
                .then(response => {
                    if (response.data.message == "This Section data deleted successfully") {
                        this.messages = {
                            text: "Section Deleted successfully",
                            type: 'success',
                        }
                    } else {
                        this.messages = {
                            text: "Event Faild",
                            type: 'error',
                        }
                    }
                })
            setTimeout(() => {
                this.messages = {
                    text: "",
                    type: 'null',
                }
            }, 3000)

            this.popupsectionDelete = false
            await this.sectionInfoGet()
            //  setTimeout(()=>{this.$router.go(0);},1000)

        }
    },

});
</script>

<style scoped>
p {
    margin-bottom: 0px;
}
</style>
