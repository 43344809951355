<template>
  <div class="min-h-screen relative">
    <Navigation />

    <div
      v-if="activeTab == null"
      class="min-h-screen w-full container-default-1209px w-container"
    >
      <p class="my-3">Course Curriculum</p>
      <div
        class="flex flex-wrap justify-start w-full h-auto overflow-y-auto py-4 gap-4"
      >
        <div
          v-for="(course, index) in this.courseDetails"
          :key="course"
          class="w-80 card1"
        >
          <div>
            <div class="text-center flex flex-col justify-center items-center">
              <img
                class="rounded text-center h-full py-10"
                src="@/assets/coding.svg"
                width="100"
                height="150"
                alt=""
              />
            </div>

            <div class="grid grid-cols-2 gap-2 px-3">
              <p class="text-xs text-gray-900 px-4 mb-0">
                Batch: {{ course.batch.year }}
              </p>
              <p class="text-xs text-gray-900 px-4 mb-0">
                Graduration: {{ course.graduration.name }}
              </p>
              <p class="text-xs text-gray-900 px-4 mb-0">
                Semester: {{ course.semester.semester }}
              </p>
              <p class="text-xs text-gray-900 px-4 mb-0">
                Program & Elective: {{ course.program.name }}
                {{ course.elective.name }}
              </p>
              <p class="text-xs text-gray-900 px-4 mb-0">
                Elective Code: {{ course.program.name }}
                {{ course.elective.elective_code }}
              </p>
            </div>

            <button
              class="bg-primary text-xs px-3 py-1 m-4 rounded-lg"
              @click="setActivePage(index)"
            >
              View Course Details
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="activeTab != null"
      class="min-h-screen w-full container-default-1209px w-container"
    >
      <p class="my-3">Course Curriculum</p>

      <button
        class="bg-primary text-xs px-3 py-1 rounded-lg"
        @click="activeTab = null"
      >
        Back
      </button>

      <div class="flex flex-col w-full h-auto overflow-y-auto py-4 gap-4">
        <div>
          <div class="text-center flex flex-col justify-center items-center">
            <img
              class="rounded text-center h-full py-10"
              src="@/assets/coding.svg"
              width="200"
              height="300"
              alt=""
            />
          </div>

          <div class="grid grid-cols-2 gap-2 px-3">
            <p class="text-sm text-gray-900 px-4 mb-0">
              Batch: {{ activeData.batch.year }}
            </p>
            <p class="text-sm text-gray-900 px-4 mb-0">
              Graduration: {{ activeData.graduration.name }}
            </p>
            <p class="text-sm text-gray-900 px-4 mb-0">
              Semester: {{ activeData.semester.semester }}
            </p>
            <p class="text-sm text-gray-900 px-4 mb-0">
              Program & Elective: {{ activeData.program.name }}
              {{ activeData.elective.name }}
            </p>
            <p class="text-sm text-gray-900 px-4 mb-0">
              Elective Code: {{ activeData.program.name }}
              {{ activeData.elective.elective_code }}
            </p>
          </div>

          <div class="flex flex-col gap-2 px-3 mt-4">
            <p class="text-sm text-gray-900 px-4 mb-0 font-semibold">
              Dependency
            </p>
            <p class="text-sm text-gray-900 px-4 mb-0">
              {{ activeData.dependency }}
            </p>
          </div>

          <div class="flex flex-col gap-2 px-3 mt-4">
            <p class="text-sm text-gray-900 px-4 mb-0 font-semibold">
              Prerequiste
            </p>
            <p class="text-sm text-gray-900 px-4 mb-0">
              {{ activeData.prerequiste }}
            </p>
          </div>

          <div class="px-7 mt-10 flex items-center gap-3">
            <!-- <a :href="baseUrl+activeData.file" class="bg-primary text-sm px-3 py-1 rounded-lg">View Curriculum</a> -->
            <a
              :href="baseUrl + activeData.file"
              class="bg-primary text-sm px-3 py-1 rounded-lg"
              download
              >Download Curriculum</a
            >
          </div>

          <!-- <iframe :src="baseUrl+activeData.file" frameborder="0"></iframe> -->

          <div class="px-8"> 
            <embed
            :src="baseUrl + activeData.file"
            style="width: 100%; margin-top: 50px; height: 500px"
          />
          </div>

         

          <!-- <button @click="downloadMethod(baseUrl + activeData.file)">Download File</button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import FlashMessage from "@/components/flashMessage.vue";
import { mapGetters } from "vuex";
import Navigation from "@/components/Navigation.vue";

export default {
  data() {
    return {
      courseDetails: [],
      activeTab: null,
      activeData: "",
      baseUrl: process.env.VUE_APP_API,
    };
  },

  components: {
    Navigation,
    FlashMessage,
  },

  computed: mapGetters(["userInfo"]),

  mounted() {},

  created() {
    this.getCourseAll();
  },

  methods: {
    downloadMethod(links) {
      // const content = 'Hello, this is the content of the file!';
      //     const blob = new Blob([content], { type: 'text/plain' });

      //     const link = document.createElement('a');
      //     link.href = URL.createObjectURL(blob);
      //     link.download = 'sample.txt';

      //     link.click();

      // const fileUrl = "https://example.com/your-file.pdf";

      const link = document.createElement("a");
      link.href = links; 
      link.download = "course_curriculam"; 

      link.click();
    },

    setActivePage(index) {
      this.activeTab = index;
      this.activeData = this.courseDetails[index];
    },

    async getCourseAll() {
      await axios
        .get(`${process.env.VUE_APP_API}/get-all-course-curriculam/`)
        .then((response) => {
          if (response.data.status == 200) {
            this.courseDetails = response.data.data;
            console.log("response::", response.data.data);
          }
        });
    },
  },
};
</script>

<style scoped></style>
