<template>

    <div> 
        <Navigation />

        <div class="h-screen flex ">
            <internLaftNav actived='Dashboard' />

            <div class="flex flex-col gap-2 px-2 border overflow-y-auto">
                
                <div class="flex h-10 py-1 justify-left px-2 md:px-6 bg-gray-100 text-teal-800">
                    <div v-if="organizationdetaill != null" class=" text-lg lg:text-2xl font-semibold capitalize">{{organizationdetaill.title}}</div>
                </div>

                <!-- sdgsdgsdg  {{ this.dashboardinfo }} -->

                <!-- over View part-1 -->
                <div class="flex flex-row w-full px-4 gap-8">
                    <!-- 1st 4 container -->
                    <div class="twobox">
                        <div class="eightbox ">
                            <div class="h-1/2 font-bold text-2xl">{{this.dashboardinfo.TotalCourse}}</div>
                            <div>Total Courses</div>
                        </div>
                        <div class="eightbox ">

                            <div class="h-1/2 font-bold text-2xl">{{this.dashboardinfo.PublishedCourse}}</div>
                            <div>Published Courses</div>
                        </div>
                    </div>

                    <div class="twobox">
                        <div class="eightbox ">
                            <div class="h-1/2 font-bold text-2xl">{{this.dashboardinfo.TotalInstructor}}</div>
                            <div>Total Instructors</div>
                        </div>
                        <div class="eightbox ">
                            <div class="h-1/2 font-bold text-2xl">{{this.dashboardinfo.InvitePendingInstructor}}</div>
                            <div>Instructors Inivitations Pending</div>
                        </div>
                    </div>
                    <!-- Last 4 -->
                    <div class="twobox">
                        <div @click="duplicateRecords_remove_in_object_list(this.studentInfo)" class="eightbox ">
                            <div class="h-1/2 font-bold text-2xl">{{this.dashboardinfo.TotalStudent}}</div>
                            <div>Total Students </div>
                        </div>
                        <div class="eightbox ">
                            <div class="h-1/2 font-bold text-2xl">{{this.dashboardinfo.ActiveStudent}}</div>
                            <div>Active Students </div>
                        </div>
                    </div>
                    <div class="twobox">
                        <div class="eightbox ">
                            <div class="h-1/2 font-bold text-2xl">{{this.dashboardinfo.InvitePendingStudent}}</div>
                            <div>Students inivitations Pending</div>
                        </div>
                        <div class="eightbox ">
                            <div class="h-1/2 font-bold text-2xl">{{this.dashboardinfo.UnpublishedCourse}}</div>
                            <div>Unpublished Courses</div>
                        </div>
                    </div>

                </div>

                <div class="px-6 bg-gray-100 m-4 rounded-lg py-10"> 
                    <div class="">
                    <!-- <p class="text-center mt-2">Content driving skill growth</p>
                    <p class="text-base text-center">
                    Courses that are contributing to learner growth
                    </p> -->
                    <div class="min-w-full">
                        <p class="text-lg text-center">Courses Contributing to skill</p>
                        <br/>
                        <table class="min-w-full h-full border-separate">
                            <thead>
                            <tr>
                                <th
                                class="px-6 py-3 border-b bg-gray-300 border-black text-left leading-4 text-gray-900 tracking-wider rounded-tl-lg"
                                ></th>
                                <th
                                class="px-6 py-3 border-b bg-gray-300 border-black text-left text-sm leading-4 text-gray-900 tracking-wider w-1/2"
                                >
                                Course Name
                                </th>
                                <th
                                class="px-6 py-3 border-b bg-gray-300 border-black text-left text-sm leading-4 text-gray-900 tracking-wider w-1/2 rounded-tr-lg"
                                >
                                Contribution
                                </th>
                            </tr>
                            </thead>
                            <tbody class="bg-white text-sm">
                            
                            <!-- dummy data -->

                            <!-- course_count_user -->
                            
                            
                            <!-- <tr v-for="(orgcoursedata, index) in orgcoursedetails_data" :key="orgcoursedata">
                                <td class="px-6 py-1 whitespace-no-wrap border-r border-black">
                                    {{ index+1 }}
                                </td>
                                <td class="px-6 py-1 whitespace-no-wrap border-r border-black">
                                    {{ orgcoursedata.title }}
                                </td>
                                <td
                                class="px-6 py-1 bg-gray-200 whitespace-no-wrap text-black border-b border-gray-500"
                                >
                                9.4%
                                </td>
                            </tr> -->

                            <tr v-for="(orgcoursedata, index) in course_count_user" :key="orgcoursedata">
                                <td class="px-6 py-1 whitespace-no-wrap border-r border-black">
                                    {{ index+1 }}
                                </td>
                                <td class="px-6 py-1 whitespace-no-wrap border-r border-black">
                                    {{ orgcoursedata.title }}
                                </td>
                                <td
                                class="px-6 py-1 bg-gray-200 whitespace-no-wrap text-black border-b border-gray-500"
                                >
                                    {{ orgcoursedata.count % 100 }}%
                                </td>
                            </tr>

                            <!-- <tr>
                                <td class="px-6 py-1 whitespace-no-wrap border-r border-black">
                                1
                                </td>
                                <td class="px-6 py-1 whitespace-no-wrap border-r border-black">
                                Mathematical thinking in computer science
                                </td>
                                <td
                                class="px-6 py-1 bg-green-500 whitespace-no-wrap text-black border-b border-gray-500"
                                >
                                9.4%
                                </td>
                            </tr>
                            <tr class="bg-gray-100">
                                <td class="px-6 py-1 whitespace-no-wrap border-r border-black">
                                2
                                </td>
                                <td class="px-6 py-1 whitespace-no-wrap border-r border-black">
                                Microeconomics:The Power of Markets
                                </td>
                                <td
                                class="px-6 py-1 bg-green-500 bg-opacity-90 whitespace-no-wrap border-b border-gray-500 text-black"
                                >
                                5.5%
                                </td>
                            </tr>
                            <tr>
                                <td class="px-6 py-1 whitespace-no-wrap border-r border-black">
                                3
                                </td>
                                <td class="px-6 py-1 whitespace-no-wrap border-r border-black">
                                Differential Equations Part I Basic Theory
                                </td>
                                <td
                                class="px-6 py-1 bg-green-500 bg-opacity-80 whitespace-no-wrap text-black border-b border-gray-500"
                                >
                                4.3%
                                </td>
                            </tr>
                            <tr class="bg-gray-100">
                                <td class="px-6 py-1 whitespace-no-wrap border-r border-black">
                                4
                                </td>
                                <td class="px-6 py-1 whitespace-no-wrap border-r border-black">
                                Data Structures
                                </td>
                                <td
                                class="px-6 py-1 bg-green-500 bg-opacity-70 whitespace-no-wrap border-b border-gray-500 text-black"
                                >
                                4.2%
                                </td>
                            </tr>
                            <tr>
                                <td class="px-6 py-1 whitespace-no-wrap border-r border-black">
                                5
                                </td>
                                <td class="px-6 py-1 whitespace-no-wrap border-r border-black">
                                Ordered Data Structures
                                </td>
                                <td
                                class="px-6 py-1 bg-green-500 bg-opacity-60 whitespace-no-wrap text-black border-b border-gray-500"
                                >
                                3.4%
                                </td>
                            </tr>
                            <tr class="bg-gray-100">
                                <td class="px-6 py-1 whitespace-no-wrap border-r border-black">
                                6
                                </td>
                                <td class="px-6 py-1 whitespace-no-wrap border-r border-black">
                                Emotions and Social Intelligence
                                </td>
                                <td
                                class="px-6 py-1 bg-green-500 bg-opacity-50 whitespace-no-wrap border-b border-gray-500 text-black"
                                >
                                2.7%
                                </td>
                            </tr>
                            <tr>
                                <td class="px-6 py-1 whitespace-no-wrap border-r border-black">
                                7
                                </td>
                                <td class="px-6 py-1 whitespace-no-wrap border-r border-black">
                                Machine Learning: Classification
                                </td>
                                <td
                                class="px-6 py-1 bg-green-500 whitespace-no-wrap bg-opacity-40 text-black border-b border-gray-500"
                                >
                                2.6%
                                </td>
                            </tr>
                            <tr class="bg-gray-100">
                                <td class="px-6 py-1 whitespace-no-wrap border-r border-black">
                                8
                                </td>
                                <td class="px-6 py-1 whitespace-no-wrap border-r border-black">
                                Data Visualization in Excel
                                </td>
                                <td
                                class="px-6 py-1 bg-green-500 bg-opacity-30 whitespace-no-wrap border-b border-gray-500 text-black"
                                >
                                2.6%
                                </td>
                            </tr> -->
                            </tbody>
                        </table>
                        </div>
                    </div>

                </div>

            
                <tryChart />

                <br/>

                <!-- over view part-2 -->
                <div class="h-4/6 flex flex-col md:flex-row gap-4 items-center m-4 p-4">

                    <div class="">
                        <div class="px-2 mt-4">Revenue</div>
                        <img src="https://experience.sap.com/wp-content/uploads/sites/56/2017/11/Line-chart-using-pallettes_012.png" alt="">
                    </div>
                </div>

                <!-- {{ course_count_user }} -->

            </div>
        </div>

    </div>

    

</template>

<script>
import axios from "axios"
import Navigation from "@/components/Navigation.vue"
import internLaftNav from "@/views/organization/leftNavIntern.vue"

import tryChart from "./tryChart.vue";

import {
    mapGetters
} from "vuex"
export default {
    data() {
        return {
            organizationdetaill: null,
            orgcoursedetails_data: null,
            orgcoursedetails: null,
            instructorInfo: null,
            studentInfo: null,
            dashboardinfo: {
                TotalCourse: "",
                TotalStudent: "",
                TotalInstructor: "",
                InvitePendingStudent: "",
                PublishedCourse: "",
                UnpublishedCourse: "",
                ActiveStudent: "",
                InvitePendingInstructor: ""
            },
            studentList: null,
            course_count_user: [],
        }
    },
    computed: mapGetters(["userInfo"]),
    components: {
        Navigation,
        internLaftNav,
        tryChart,
    },
    async created() {

        let token_id = this.userInfo.token_key
        await axios.get(`${process.env.VUE_APP_API}/organization_detail/?Key=${token_id}`)
            .then(response => {
                this.organizationdetaill = response.data.data;
            })

        await axios.get(`${process.env.VUE_APP_API}/courses/?organization_id=${this.organizationdetaill.id}`)
            .then(response => {
                this.orgcoursedetails = response.data.data;
                this.orgcoursedetails_data = response.data.data;
            })

        
        await axios.get(`${process.env.VUE_APP_API}/instructordetaill/?Key=${this.userInfo.token_key}`)
            .then(response => this.instructorInfo = response.data.data)

        await axios.get(`${process.env.VUE_APP_API}/studentsdetaill/?Key=${this.userInfo.token_key}`)
            .then(response => {
                this.studentInfo = response.data.data
                let uniqueRecords = []
                let uniqueRecords_id = []
                this.studentInfo.forEach(record => {
                    if (uniqueRecords_id.indexOf(record.students_email) === -1) {
                        uniqueRecords.push(record)
                        uniqueRecords_id.push(record.students_email)

                    }
                })
                this.studentInfo = uniqueRecords
            })
        
      
        this.dashboardinfo.TotalCourse = this.orgcoursedetails.length
        this.dashboardinfo.PublishedCourse = this.orgcoursedetails.filter(course => course.is_published == true).length
        this.dashboardinfo.UnpublishedCourse = this.orgcoursedetails.filter(course => course.is_published == false).length
        this.dashboardinfo.TotalInstructor = this.instructorInfo.length
        this.dashboardinfo.InvitePendingInstructor = this.instructorInfo.filter(instructor => instructor.published == false).length
        this.dashboardinfo.TotalStudent = this.studentInfo.length
        this.dashboardinfo.ActiveStudent = this.studentInfo.filter(student => student.students_Status == true).length
        this.dashboardinfo.InvitePendingStudent = this.studentInfo.filter(student => student.students_Status == false).length
        


        // this.studentList = 
        this.getCountStudentofCourse();
    }, 


    methods: {
        async getCountStudentofCourse(){
            for(let i=0; i<this.orgcoursedetails_data.length; i++){
                await axios.get(`${process.env.VUE_APP_API}/all_list/${this.orgcoursedetails_data[i].id}/?Key=${this.userInfo.token_key}`)
                .then(response => {
                    // console.log(response.data.count, "count::")
                    // this.studentList = response.count;
                    setTimeout(()=>{
                        // console.log("get list:::", {'id': this.orgcoursedetails_data[i].id, 'title': this.orgcoursedetails_data[i].title, 'count': response.data.count});
                        this.course_count_user.push({'id': this.orgcoursedetails_data[i].id, 'title': this.orgcoursedetails_data[i].title, 'count': response.data.count});
                    }, 0)

                })
            }
           
        }
    },

}
</script>

<style scoped>
.eightbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-width: 1px;
    border-color: #b2d8d8;
    color: #006666;
    height: 6rem;
    width: 20rem;
    align-items: center;
    border-radius: 0.5rem;
    --tw-shadow: 0 25px 50px -26px rgba(0, 0, 0, 0.1);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    font-size: 1.05rem;
    line-height: 1.75rem;
}

.twobox {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

@media (min-width: 640px) and (max-width:1024px) {
    .eightbox {
        height: 6rem;
        width: 8rem;
        font-size: 0.875rem;
        line-height: 1.25rem;
    }

}
</style>
