<template>
  <div class="min-h-screen">
    <div
      class="w-full lg:max-w-3xl 2xl:max-w-4xl mx-auto relative bg-gray-50 m-4 p-8 rounded-md border shadow-lg"
    >
      <form
        action=""
        @submit.prevent="NewCourse()"
        class="w-full lg:p-4 mx-auto"
      >
        <h1 class="text-left py-2 text-3xl font-semibold text-gray-600">
          Course with AI
        </h1>
        <!-- Step 1 -->
        <div v-if="currentStep === 1" class="flex flex-col gap-2">
          <p>Step 1</p>
          <div class="">
            <label for="category" class="flex gap-2 items-center">
              <div>
                Category<span class="text-red-400"
                  >*
                  <img
                    src="@/assets/exclamationMark.svg"
                    alt=""
                    srcset=""
                    class="w-4 h-4 tooltip-trigger"
                  />
                  <span
                    class="tooltip-content absolute bg-gray-700 text-white text-xs rounded py-1 px-2"
                  >
                    Please select a category.
                  </span>
                </span>
              </div>
            </label>

            <select
              name="category"
              v-model="form.category"
              required
              class="p-2 block w-full rounded-md text-sm"
              id="category"
            >
              <option value="" selected disabled>Select Category</option>
              <option
                v-for="category in categoryData"
                :key="category.id"
                :value="category.id"
              >
                {{ category.title }}
              </option>
            </select>
          </div>
          <!-- <div class="">
            <label for="batch">
              Batch<span class="text-red-400">*</span>
            </label>
            <input
              v-model="form.batch"
              required
              id="batch"
              type="number"
              placeholder="Ex.batch"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
            />
          </div> -->
          <div class="">
            <label for="code">
              Code<span class="text-red-400"
                >*
                <img
                  src="@/assets/exclamationMark.svg"
                  alt=""
                  srcset=""
                  class="w-4 h-4 tooltip-trigger"
                />
                <span
                  class="tooltip-content absolute bg-gray-700 text-white text-xs rounded py-1 px-2"
                >
                  Please select a code.
                </span></span
              >
            </label>
            <input
              v-model="form.code"
              required
              id="code"
              type="text"
              placeholder="Ex.code (MCA2024JUL)"
              class="border border-gray-300 rounded-md text-sm w-full p-2 uppercase"
            />
          </div>
          <div class="flex justify-end">
            <button class="gradient-btn mt-4" @click="nextPage">Next</button>
          </div>
        </div>

        <!-- Step 2 -->
        <div v-if="currentStep === 2" class="flex flex-col gap-2">
          <p>Step 2</p>
          <div>
            <label for="domain"
              >Domain<span class="text-red-400"
                >*
                <img
                  src="@/assets/exclamationMark.svg"
                  alt=""
                  srcset=""
                  class="w-4 h-4 tooltip-trigger"
                />
                <span
                  class="tooltip-content absolute bg-gray-700 text-white text-xs rounded py-1 px-2"
                >
                  Please select a deomain.
                </span></span
              ></label
            >
            <input
              v-model="form.domain"
              required
              id="domain"
              type="text"
              placeholder="Ex.domain (Maths, Physics, etc ..)"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
            />
          </div>
          <div>
            <label for="title"
              >Title<span class="text-red-400"
                >*
                <img
                  src="@/assets/exclamationMark.svg"
                  alt=""
                  srcset=""
                  class="w-4 h-4 tooltip-trigger"
                />
                <span
                  class="tooltip-content absolute bg-gray-700 text-white text-xs rounded py-1 px-2"
                >
                  Please select a course title.
                </span></span
              ></label
            >
            <input
              v-model="form.title"
              required
              id="title"
              type="text"
              placeholder="Course Title"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
            />
          </div>
          <div class="flex justify-between">
            <button type="button" class="gradient-btn mt-4" @click="prevPage">
              Previous
            </button>
            <button type="button" class="gradient-btn mt-4" @click="nextPage">
              Next
            </button>
          </div>
        </div>

        <!-- Step 3 -->
        <div v-if="currentStep === 3" class="flex flex-col gap-2">
          <p>Step 3</p>
          <div>
            <label for="units"
              >Unit title<span class="text-red-400"
                >*<img
                  src="@/assets/exclamationMark.svg"
                  alt=""
                  srcset=""
                  class="w-4 h-4 tooltip-trigger"
                />
                <span
                  class="tooltip-content absolute bg-gray-700 text-white text-xs rounded py-1 px-2"
                >
                  Please select a Unit title.
                </span></span
              ></label
            >
            <input
              v-model="form.unit_title"
              required
              id="unit title"
              type="text"
              placeholder="Ex.unit title"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
            />
          </div>
          <div>
            <label for="units"
              >Unit name<span class="text-red-400"
                >*
                <img
                  src="@/assets/exclamationMark.svg"
                  alt=""
                  srcset=""
                  class="w-4 h-4 tooltip-trigger"
                />
                <span
                  class="tooltip-content absolute bg-gray-700 text-white text-xs rounded py-1 px-2"
                >
                  Please select a Unit name.
                </span>
              </span></label
            >
            <input
              v-model="form.units"
              required
              id="units"
              type="text"
              placeholder="Ex.units"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
            />
          </div>
          <div>
            <label for="size"
              >Unit size<span class="text-red-400"
                >*
                <img
                  src="@/assets/exclamationMark.svg"
                  alt=""
                  srcset=""
                  class="w-4 h-4 tooltip-trigger"
                />
                <span
                  class="tooltip-content absolute bg-gray-700 text-white text-xs rounded py-1 px-2"
                >
                  Please select a Unit size.
                </span></span
              ></label
            >
            <input
              v-model="form.size"
              required
              id="size"
              type="number"
              placeholder="Ex.unit size"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
            />
          </div>

          <div>
            <label for="unit_instruction"
              >Unit instruction promt<span class="text-red-400"
                >*
                <img
                  src="@/assets/exclamationMark.svg"
                  alt=""
                  srcset=""
                  class="w-4 h-4 tooltip-trigger"
                />
                <span
                  class="tooltip-content absolute bg-gray-700 text-white text-xs rounded py-1 px-2"
                >
                  Please select a Unit instruction promt.
                </span></span
              ></label
            >

            <textarea
              name=""
              v-model="form.unit_instruction"
              required
              id=""
              cols="20"
              rows="1"
              placeholder="Ex.instruction promt"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
            >
            </textarea>
          </div>

          <div class="flex justify-start items-center gap-2">
            <label for="freeCourse">Assignments </label>
            <input
              id="Assessments"
              type="checkbox"
              class=""
              v-model="form.AssignDatastatus"
              required
              @change="toggleSub"
            />
          </div>

          <div v-if="isAssessmentsActive">
            <label for="assessmentDetails">
              Number of assignments
              <span class="text-red-400"
                >*
                <img
                  src="@/assets/exclamationMark.svg"
                  alt=""
                  srcset=""
                  class="w-4 h-4 tooltip-trigger"
                />
                <span
                  class="tooltip-content absolute bg-gray-700 text-white text-xs rounded py-1 px-2"
                >
                  Please select a Number of assignment.
                </span></span
              ></label
            >
            <input
              v-model="form.AssignData"
              required
              id="AssignData"
              type="number"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
              placeholder="Ex.number of assignments"
            />
          </div>

          <div class="flex justify-start items-center gap-2">
            <label for="freeCourse">Quizs </label>
            <input
              id="Quizs"
              type="checkbox"
              class=""
              v-model="form.QuizDatastatus"
              required
              @change="toggleSubquiz"
            />
          </div>

          <div v-if="isQuizActive">
            <label for="quizDetails"
              >Number of quizs
              <span class="text-red-400"
                >*
                <img
                  src="@/assets/exclamationMark.svg"
                  alt=""
                  srcset=""
                  class="w-4 h-4 tooltip-trigger"
                />
                <span
                  class="tooltip-content absolute bg-gray-700 text-white text-xs rounded py-1 px-2"
                >
                  Please select a Number of quizs.
                </span></span
              ></label
            >
            <input
              v-model="form.QuizData"
              required
              id="QuizData"
              type="number"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
              placeholder="Ex.number of quizs"
            />
          </div>

          <div>
            <label for="instruction"
              >Course Instruction promt<span class="text-red-400"
                >*
                <img
                  src="@/assets/exclamationMark.svg"
                  alt=""
                  srcset=""
                  class="w-4 h-4 tooltip-trigger"
                />
                <span
                  class="tooltip-content absolute bg-gray-700 text-white text-xs rounded py-1 px-2"
                >
                  Please select a Unit course instruction promt.
                </span></span
              ></label
            >

            <textarea
              name=""
              v-model="form.instruction"
              required
              id=""
              cols="20"
              rows="5"
              placeholder="Ex.course instruction"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
            >
            </textarea>
          </div>
          <div class="flex justify-between">
            <button type="button" class="gradient-btn mt-4" @click="prevPage">
              Previous
            </button>
            <button type="submit" class="gradient-btn mt-4">Save</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "NewCourse",
  data() {
    return {
      currentStep: 1,
      form: {
        category: "",
        // batch: "",
        code: "",
        domain: "",
        title: "",
        units: "",
        unit_title: "",
        unit_instruction: "",
        size: "",
        instruction: "",
        AssignDatastatus: "",
        QuizDatastatus: "",
        AssignData: "",
        QuizData: "",
      },
      isAssessmentsActive: false,
      isQuizActive: false,
      categoryData: [],
    };
  },

  async created() {
    await this.fetchcategory();
  },
  computed: mapGetters(["userInfo"]),

  methods: {
    nextPage() {
      const isValid = this.validateCurrentStep();
      if (isValid) {
        this.currentStep++;
      }
    },
    validateCurrentStep() {
      // Basic validation for the current step
      const fields = {
        1: ["category", "code"],
        2: ["domain", "title"],
        3: ["units", "size", "Assessments", "Quiz", "instruction"],
      };
      return fields[this.currentStep].every((field) => this.form[field]);
    },
    prevPage() {
      this.currentStep--;
    },

    toggleSub(event) {
      this.isAssessmentsActive = !this.isAssessmentsActive;
    },
    toggleSubquiz() {
      this.isQuizActive = !this.isQuizActive;
    },

    NewCourse() {
      console.log("form//////", this.form);
      // this.$router.push({
      //   name: "NewCoursepreview",
      //   query: {
      //     category: this.form.category,
      //     // batch: this.form.batch,
      //     code: this.form.code,
      //     domain: this.form.domain,
      //     title: this.form.title,
      //     units: this.form.units,
      //     size: this.form.size,
      //     unit_title: this.form.unit_title,
      //     unit_instruction: this.form.unit_instruction,
      //     AssignDatastatus: this.form.AssignDatastatus,
      //     QuizDatastatus: this.form.QuizDatastatus,
      //     AssignData: this.form.AssignData,
      //     QuizData: this.form.QuizData,
      //     instruction: this.form.instruction,
      //   },
      // });
      localStorage.setItem("formatData", JSON.stringify(this.form));

      this.$router.push({ name: "NewCoursepreview" });
      // const codedata = {
      //   code: this.form.code,
      //   title: this.form.title,
      // };
      // console.log("codedata", codedata);
      // console.log("userinfo", this.userInfo.token_key);

      // axios
      //   .post(
      //     `${process.env.VUE_APP_API}/course/title-code/?Key=${this.userInfo.token_key}`,
      //     codedata
      //   )
      //   .then((response) => {
      //     console.log("response", response);
      //     if (response.data.status == 200) {
      //       this.unicode = response.data;
      //     }
      // localStorage.setItem("formatData", JSON.stringify(this.form));

      // this.$router.push({ name: "NewCoursepreview" });
      //   })
      //   .catch((err) => {
      //     console.log("error", err);
      //   });
      console.log("query", this.$route.query);
      console.log("this.form.category", this.form.category);
    },
    async fetchcategory() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API}/category/`
        );
        this.categoryData = response.data.data;
        console.log("categoryData", this.categoryData);
      } catch (error) {
        console.error("Error fetching categoryData:", error);
      }
    },
  },
};
</script>

<style>
.tooltip-content {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s ease;
  position: absolute;
  bottom: -90%; /* Tooltip appears above the icon */
  left: 100px;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 4px;
  z-index: 100;
  white-space: nowrap;
}

.tooltip-trigger {
  position: relative;
}

.tooltip-trigger:hover + .tooltip-content,
.tooltip-trigger:focus + .tooltip-content {
  visibility: visible;
  opacity: 1;
}
</style>
