<template lang="">
    <div class="mainContentForStudent flex bg-gray-50">
        
        
        <leftNavigationStudent actived='course_step' v-if="!isLessonTab" />
        


        <div class="w-full h-full relative overflow-y-scroll">

            <div v-if="!isLessonTab"> 

                <div class="px-4 pt-3 flex flex-col gap-4 mt-10 lg:mt-0"> 

                    <p class="text-lg lg:text-lg mb-0 font-semibold">Course Curriculum</p>

                    <!-- <div class="flex">
                        <p class="text-sm bg-primary py-1 px-2 rounded-lg cursor-pointer hidden lg:flex" @click="setUrlTap(null, null, null)">Start lesson</p>
                    </div> -->
                </div>

                <div class="px-10 py-1 flex flex-col gap-4 mt-5 overflow-y-auto">
                        <div v-if="sectionInfo != null"> 
                            <div v-for="(section, index) in sectionInfo.data" :key="section">
                                <div class="videos">

                                    <!-- {{ lessonInfo.results.length }} -->
                                
                                    <p class="px-2 py-2 text-gray-800 text-sm font-semibold mb-0">{{ section.title }}</p>
                                    <div v-if="Object.keys(this.lessonInfo) != 'error'" class="videos-wrapper"> 
                                        <div v-for="(lesson, index) in lessonInfo.results" :key="index">
                                            <div v-if="section.id == lesson.section_id" class="flex flex-col lg:flex-row lg:items-center justify-between bg-gray-200 mb-2">
                                                <h3 class="text-sm course-video h-full px-4 py-2 m-0 flex items-center">{{lesson.title}}</h3>
                                                <div class="flex">
                                                    <p class="text-sm bg-primary py-1 px-4 rounded-lg mt-2 mr-4 ml-4 cursor-pointer" @click="setUrlTap(lesson.video_url, index, lesson)" >Start</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                </div>
            </div>
    
            <div class="flex w-ful h-full" v-if="isLessonTab">

                <div class="hidden lg:flex flex-col chatContainer w-80 lg:w-3/12 2xl:w-1/6 bg-white overflow-y-auto shadow-lg">
                    <p class="text-base lg:text-md mb-0 px-3 py-2 font-semibold">Course Curriculum</p>
                    <div class="py-3 text-base lg:text-sm bg-white mb-0 cursor-pointer flex">
                        <div v-if="sectionInfo != null"> 
                            <div v-for="section in sectionInfo.data" :key="section">
                                <div class="videos">
                                  
                                    <p class="px-2 text-base font-semibold text-gray-800 py-3 mb-0">{{section.title}}</p>

                                    <div v-if="Object.keys(this.lessonInfo) != 'error'" class="videos-wrapper"> 
                                        <div v-for="(lesson, index) in lessonInfo.results" :key="lesson">
                                            <div v-if="section.id == lesson.section_id" @click="setUrl(lesson.video_url, index, lesson)" class="mb-3">
                                                <h3 class="text-sm h-full px-4 py-2 m-0 flex items-center bg-gray-100" 
                                                :class="{'text-blue-800' : activeIndex === index }">{{lesson.title}}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div id="videoFile" class="w-full overflow-y-scroll">

                    <div class="flex justify-between mt-6">
                        <div>
                            <p class="text-sm bg-primary py-1.5 px-4 rounded-lg mt-2 mx-4 cursor-pointer" @click="isLessonTab = false">Back to Curriculum</p>
                        </div>
                        <!-- <div class="flex items-center gap-2">
                            <p class="text-sm bg-primary py-1.5 px-4 rounded-lg mt-2 mx-4 cursor-pointer" @click="prevCourse(index)">Prev</p>
                            <p class="text-sm bg-primary py-1.5 px-4 rounded-lg mt-2 mx-4 cursor-pointer" @click="nextCourse(index)">Next</p>
                        </div> -->
                    </div> 

                    <div v-if="this.videoLink" class="w-full p-3 h-full flex flex-col">

                        <h1 class="text-2xl font-semibold text-primary mt-2 px-3">{{ selectedlessonData.title }}</h1>

                        <iframe class="w-full h-full mx-auto rounded p-3" :src="this.videoLink" title="YouTube video player" 
                        frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen> </iframe>
                        
                        <v-md-preview :text="selectedlessonData.content"></v-md-preview>
                        

                    </div>

                    <div v-else class="w-full h-full flex items-center justify-center">
                        <h1 class="text-center text-xl py-4">Course Lesson's</h1>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
    
<script>
import leftNavigationStudent from "@/views/studentDashboard/leftnav.vue";
import axios from "axios";
import {
    mapGetters
} from "vuex";
import $ from "jquery";

import '@uivjs/vue-markdown-preview/markdown.css';
import MarkdownPreview from '@uivjs/vue-markdown-preview';


export default {
    data() {
        return {
            messageVal: null,
            totalmessage: null,
            studentList: "",
            selectedStudent: null,
            ChatpopUp: false,
            instructorChat: [{
                id: "",
                display_name: "",
            }, ],
            sectionInfo: "",
            lessonInfo: "",
            videoLink: "",
            activeIndex: null,
            isLessonTab: false,
            selectedlessonData: null,
            MarkdownPreview,
        };
    },

    components: {
        leftNavigationStudent,
    },

    props: ["course_id", "slug", "instructor_id", "course_name"],

    computed: {
        ...mapGetters(["userInfo"]),
    },

    created(){
        this.detailsgets();
    },

    async mounted() {

      
        // alert(this.lessonInfo.results[0]);
        // console.log(this.lessonInfo.results[0].video_url, "set url:::");
        // this.videoLink = this.lessonInfo.results[0].video_url;
        // console.log(this.course_id,this.slug)

        setInterval(function () {
            var headerHeight = $(".header[data-collapse]").outerHeight();
            var userInputHeight = $(".userInput").outerHeight();
            var headline = $(".headline").outerHeight();
            var remainHeight = headerHeight + userInputHeight + headline + 10;

            if ($(".mainContentForStudent").length > 0)
                $(".mainContentForStudent").css(
                    "height",
                    "calc(100vh - " + $(".header[data-collapse]").outerHeight() + "px)"
                );

            if ($(".chatStudent").length > 0)
                $(".chatStudent").css("height", "calc(100vh - " + remainHeight + "px)");
        }, 0);

        await axios
            .get(`${process.env.VUE_APP_API}/coursesdata/${this.course_id}/`)
            .then((response) => {
                this.instructorChat[0].id = response.data.user_id;
            });
        await axios
            .get(`${process.env.VUE_APP_API}/courses/?id=${parseInt(this.course_id)}`)
            .then((response) => {
                this.instructorChat[0].display_name = response.data.data[0].instructor;
            });
        this.getstudentdetails();
        this.messageInfo();
    },

    methods: {

        prevCourse(index){
            
        },

        nextCourse(index){

        },

        setUrlTap(url, index, lesson){
            this.isLessonTab = true;
            this.videoLink = url;
            this.activeIndex = index;
            this.selectedlessonData = lesson;
        },

        setUrl(url, index, lesson){
            this.videoLink = url;
            this.activeIndex = index;
            this.selectedlessonData = lesson;
        },

        async detailsgets() {


            await axios.get(`${process.env.VUE_APP_API}/section/?course_name=${this.course_id}`)
                .then(response => {
                    this.sectionInfo = response.data
                })

            
            await axios.get(`${process.env.VUE_APP_API}/lessons/?course_name=${this.course_id}`)
                .then(response => {
                    this.lessonInfo = response.data
                    if (Object.keys(this.lessonInfo) == "error") {
                        this.lessonCount = 0
                    } else {
                        this.lessonCount = this.lessonInfo.count
                    }
                });
        },

        async Instructor_Chat(data) {
            this.selectedStudent = data;
            this.ChatpopUp = true;
            await axios
                .get(
                    `${process.env.VUE_APP_API}/one_to_one_messages/${this.userInfo.user.id}/${this.selectedStudent[0].id}/?Key=${this.userInfo.token_key}`
                )
                .then((Response) => {
                    this.totalmessage = Response.data.data.reverse();
                    console.log(this.totalmessage, "message");
                    console.table(this.totalmessage);
                });

            $(".chatArea").animate({
                    scrollTop: $(".chatArea")[0].scrollHeight,
                },
                1000
            );

            // let messageDisplay = this.$refs.chatArea;
            // messageDisplay.scrollTop = messageDisplay.scrollHeight;
        },

        async getstudentdetails() {
            await axios
                .get(
                    `${process.env.VUE_APP_API}/all_list/${this.course_id}/?Key=${this.userInfo.token_key}`
                )
                .then((response) => {
                    response.data["data"] = [
                        ...new Map(
                            response.data.data.map((i) => i[0]).map((m) => [m.id, m])
                        ).values(),
                    ].map((i) => [i]);
                    this.studentList = response.data;
                });
        },

        async messageInfo(data) {
            if (data) {
                this.selectedStudent = data;
                this.ChatpopUp = true;
                await axios
                    .get(
                        `${process.env.VUE_APP_API}/one_to_one_messages/${this.userInfo.user.id}/${this.selectedStudent[0].id}/?Key=${this.userInfo.token_key}`
                    )
                    .then((Response) => {
                        this.totalmessage = Response.data.data.reverse();
                    });

                $(".chatArea").animate({
                        scrollTop: $(".chatArea")[0].scrollHeight,
                    },
                    1000
                );
                // let messageDisplay = this.$refs.chatArea;
                // messageDisplay.scrollTop = messageDisplay.scrollHeight;
            }
        },

        async sendmsg(data) {
            let messagesPass = {
                text: this.messageVal,
                owner: this.userInfo.user.id,
                client: data[0].id,
            };

            await axios
                .post(
                    `${process.env.VUE_APP_API}/talk/?Key=${this.userInfo.token_key}`,
                    messagesPass
                )
                .then((Response) => {
                    Response;
                });

            this.messageInfo(this.selectedStudent);
            this.messageVal = null;

            $(".chatArea").animate({
                    scrollTop: $(".chatArea")[0].scrollHeight,
                },
                1000
            );

            // let messageDisplay = this.$refs.chatArea;
            // messageDisplay.scrollTop = messageDisplay.scrollHeight;
        },

        clearAllMessages() {
            this.messages = [];
        },
    },
};
</script>
    
<style scoped>
    .app-menu-open .leftmenu {
        -webkit-transform: none;
        transform: none;
        transition: transform 100ms linear;
    }

    .app-menu-close {
        width: 60px;
        transition: width 1.5s, ;
        margin: auto;
    }

    .app-menu-close .contentval {
        width: 60px;
        padding-left: 0px;
        padding-right: 0px;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .app-menu-close .contentval p {
        display: none;
    }

    @media (max-width: 1023px) {
        .heightadjustment{
            height: 1000px !important;
        }
    }
    
    .heightadjustment{
        height: 500px !important;
    }

    .msgContainer {
        margin: 5px 20px 0 0;
        z-index: 10;
    }
    
    .chatContainer {
        margin: 5px 10px 0 0;
    }
    
    .chatList {
        position: sticky;
        top: 0;
        padding: 10px;
        background: #fff;
        z-index: 10;
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.1);
    }
    
    textarea::placeholder {
        font-size: 14px;
    }
    
    .chatArea {
        position: relative;
        scrollbar-color: gray white;
        padding-bottom: 15px;
        padding-top: 25px;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        background: url(../../assets/bgpattern.png) repeat center center;
        background-color: rgba(0, 0, 0, 0.1);
    }
    
    .headline {
        position: relative;
        padding: 10px;
        background: #fff;
        z-index: 10;
        margin-bottom: 0;
        box-shadow: 1px 1px 5px 1px rgb(0, 0, 0, 0.1);
    }
    
    .message {
        width: fit-content;
        max-width: 70%;
        min-width: 17%;
        margin: 6px 25px;
        padding: 10px 15px;
        border-radius: 5px;
    }
    
    .message p {
        display: flex;
        justify-content: space-between;
        align-items: center;
        word-break: break-all;
    }
    
    .senderMsg {
        position: relative;
        align-self: end;
        background: #e9ffdb;
    }
    
    .receiverMsg {
        align-self: start;
        position: relative;
        background: #fff;
    }
    
    .senderMsg::before {
        content: "";
        position: absolute;
        top: 0px;
        right: -12px;
        border-top: 17px solid #e9ffdb;
        border-left: 17px solid transparent;
        border-right: 17px solid transparent;
        transform: rotate(-20deg);
    }
    
    .receiverMsg::before {
        content: "";
        position: absolute;
        top: 0;
        left: -12px;
        border-top: 17px solid #fff;
        border-left: 17px solid transparent;
        border-right: 17px solid transparent;
        transform: rotate(20deg);
    }
    
    .chatArea::-webkit-scrollbar,
    .chatContainer::-webkit-scrollbar {
        width: 5px;
    }
    
    .chatArea:hover::-webkit-scrollbar-thumb,
    .chatContainer:hover::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.4);
    }
    
    .chatArea::-webkit-scrollbar-thumb,
    .chatContainer::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.2);
        border-radius: 10px;
    }
    
    .chatArea::-webkit-scrollbar-track,
    .chatContainer::-webkit-scrollbar-track {
        background: transparent;
    }
    
    #addbutton {
        z-index: 1000;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
    }
    
    @media screen and (max-width: 480px) {
        textarea::placeholder {
            font-size: 12px;
        }
    
        .message {
            width: fit-content;
            max-width: 85%;
            min-width: 17%;
            margin: 5px 20px;
            padding: 10px 15px;
            border-radius: 5px;
        }
    
        .receiverMsg::before {
            content: "";
            position: absolute;
            top: 0;
            left: -10px;
            border-top: 15px solid #fff;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            transform: rotate(15deg);
        }
    
        .senderMsg::before {
            content: "";
            position: absolute;
            top: 0px;
            right: -10px;
            border-top: 15px solid #e9ffdb;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            transform: rotate(-15deg);
        }
    
        .msgContainer {
            margin: 5px 5px 0 5px;
        }
    
        .chatContainer {
            margin: 5px auto 0;
            width: calc(100% - 10px);
        }
    }
    
    @media screen and (min-width: 481px) and (max-width: 1023px) {
        .msgContainer {
            margin: 5px 5px 0 5px;
        }
    }
</style>
    
