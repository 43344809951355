<template>
  <div class="page-wrapper">
    <Navigation />
    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />


      <div class="">
        <div class="container-default-1209px w-container">
          <div class="flex flex-row items-center justify-between gap-10 rounded-3xl my-20 shadow-md">
            
            <div class="px-20 flex flex-col py-14 gap-4">
              <h1 class="font-bold text-5xl prime">
                Welcome
                <span class="!text-[#105C8E] text-3xl ml-5 blues">Username</span>
              </h1>
              <p class="content max-w-sm">
                ghfghgfgfnvbnhdsahkcxhkxncknxnckjnkjkxnkjc
                nkjkscjsckjckjhkjhcsakjhckskdhckshckjsdhkcjjghfghgfgfnvbn
                hdsahkcxhkxncknxnckjnkjkxnkjcnkjkscjsckjckjhkjhcsakjhckskdhckshckjs
              </p>

              <div class="flex flex-row items-center">
                <input type="text" placeholder="Search The Course" class="rounded-full px-8 py-4 border" />
                <button class="bluesbg py-3 rounded-2xl text-white ml-10 px-10">Search</button>
              </div>

              <p class="my-6 blues text-4xl font-semibold">Enroll Now!!!</p>

            </div>
            
            <div class="flex flex-row justify-end endss gap-4 relativep">
                 <img
                  src="@/assets/new/blub.png"
                  alt=""
                  class="absolutes"
                />  
               <img
                  src="@/assets/new/boy.png"
                  alt=""
                  class="width rounded-r-3xl"
                />
            </div>

          </div>
        </div>
      </div>
    
    <!--     
    <div class="">
      <div class="container-default-1209px w-container">
        <div
          class="w-full flex justify-center items-center"
          style="height: 600px"
        >
          <div class="w-full">
            <div class="w-full slides relative">
              <div
                class="absolute top-[30%] right-[50%] w-full flex flex-col items-center justify-center z-[500]"
              >
                <img
                  src="../assets/srclogo.png"
                  alt=""
                  class="h-48 object-contain"
                />
            
              </div>
              <img
                src="../assets/background.png"
                alt=""
                class="h-96 w-full rounded px-2 object-contain"
              />
             
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="container-default-1209px w-container">
      <div
        data-w-id="0b097d78-b776-9e09-a4b6-8ab6576371c3"
        style=""
        class="divider"
      ></div>
    </div> -->

    <!-- <div
      id="Courses"
      data-w-id="4dfb3ae6-c85f-5256-cad4-afe060384279"
      style=""
      class="section latest-courses wf-section"
    >
      <div class="container-default-1209px w-container">
        <div class="latest-courses-wrapper">
          <h2
            data-w-id="4dfb3ae6-c85f-5256-cad4-afe06038427c"
            style=""
            class="title latest-courses"
          >
            My Courses
          </h2>
          <div class="w-dyn-list">
            <div
              v-if="this.allyourcourse"
              role="list"
              class="courses-grid w-dyn-items"
            >
              <div
                v-for="course in this.allyourcourse"
                :key="course"
                data-w-id="96a61bf5-bd59-e173-033b-ad374d548848"
                style="height: 528px"
                role="listitem"
                class="course-card-wrapper w-dyn-item w-80 md:w-96"
              >
                <div class="card w-80 md:w-96" style="height: 528px">
                  <router-link
                    :to="{
                      name: 'VideoRender',
                      params: { courseid: course.id },
                    }"
                    class="card w-80 md:w-96"
                    style="height: 528px"
                  >
                    <a href="#" class="card-image-link w-inline-block"
                      ><img
                        :src="course.thumbnail"
                        alt=""
                        style="overflow: hidden; height: 208px"
                        class="image course w-80 md:w-96"
                    /></a>
                    <div class="card-content">
                      <a href="#" class="card-title-link w-inline-block">
                        <h3 class="text-lg 2xl:text-xl course">
                          {{ course.title.slice(0, 80) }}
                          <span v-if="course.title.length > 80">....</span>
                        </h3>
                      </a>
                      <p class="text-base primary-text-color">
                        {{ course.short_description.slice(0, 300) }}
                        <span v-if="course.short_description.length > 300"
                          >....</span
                        >
                      </p>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
            <div v-else class="card empty-state w-dyn-empty">
              <div class="empty-state-text">
                There are no courses available yet.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-default-1209px w-container">
      <div
        data-w-id="0b097d78-b776-9e09-a4b6-8ab657637223"
        style=""
        class="divider"
      ></div>
    </div> -->

    <div class="min-h-screen container-default-1209px w-container mb-10">
      <p class="my-6 text-center blues text-4xl font-semibold">Featured Courses</p>
    
      <div
        class="grid lg:grid-cols-3 gap-4 mt-32"
      >
        <div
          v-for="course in this.courselist.slice(0,3)"
          :key="course"
          class="heightc card1 border p-6"
        >
          <router-link
            :to="{ name: 'VideoRender', params: { courseid: course.id } }"
          >
            <div
              class="relative flex justify-center items-center"
              style="height: 60%"
            >
              <img
                v-if="course.thumbnail"
                class="object-cover object-center h-full w-full rounded-lg"
                :src="course.thumbnail"
                alt=""
              />
              <img
                v-else
                class="object-cover object-center h-24 w-52 rounded"
                src="@/assets/coding.svg"
                alt=""
              />
              <!-- <button
                class="absolute right-2 top-2 primary-color w-auto px-4 py-1 bg-opacity-70 text-sm rounded-md"
              >
                {{ course.category }}
              </button> -->
            </div>

            <div
              class="text-center flex flex-col gap-1 justify-center"
              style="height: 45%"
            >
              <p class="text-sm text-gray-600 font-semibold px-4 text-left truncate mb-0">{{ course.title }}</p>
              <p class="text-xs text-gray-600 px-4 text-left truncate mb-0">{{ course.sections.length }} Units</p>

               <p class="my-3 py-2 text-sm rounded-lg bluesbg text-white font-medium">Enroll Now!!!</p>

            </div>
          </router-link>
        </div>
      </div>
  
    </div>


    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
import FlashMessage from "@/components/flashMessage.vue";
import { mapGetters } from "vuex";

export default {
  name: "Home",
  data() {
    return {
      courselist: "",
      blog_list: "",
      webinar_List: "",
      messages: {
        text: "",
        type: "null",
      },
      title: "",
      purchased_course: "",
      allyourcourse: "",
      listOfcourse: [],
      courseDetails: null,
    };
  },
  computed: mapGetters(["isAuthenticated", "userInfo"]),
  components: {
    Navigation,
    Footer,
    FlashMessage,
  },

  async created() {
    this.title = process.env.VUE_APP_Title;

    await axios
      .get(`${process.env.VUE_APP_API}/courses/?format=json`)
      .then((response) => {
        this.courselist = response.data.data;
      });

    await axios
      .get(
        `${process.env.VUE_APP_API}/studentsbuycoursedetails/?Key=${this.userInfo.token_key}`
      )
      .then((response) => {
        this.purchased_course = response.data;

        if (this.purchased_course.data.length > 0) {
          for (let el in this.purchased_course.data) {
            this.listOfcourse.push(this.purchased_course.data[el].course_id);
          }
          this.allyourcourse = this.courselist.filter((el) =>
            this.listOfcourse.includes(el.id)
          );
        } else {
          console.log("else");
        }
      });
  },

  methods: {},
};
</script>

<style scoped>

.prime{
  color: #333333 !important;
}

.blues{
  color: #105C8E !important;
}

.bluesbg{
  background-color: #105C8E !important;
}

.width{
  height: 100% !important;
}

.endss{
  display: flex !important;
  justify-items: end !important;
  justify-content: end !important;
  align-items: flex-end !important;
}

.relativep{
  position: relative !important;
}

.absolutes{
  position: absolute !important;
  top: 8% !important;
  right: 70% !important;
  z-index: 100 !important;
}

.contents{
  width: 40% !important;
}


.heightc{
  height: 400px !important;
}

</style>
