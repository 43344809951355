<template>
<div class="page-wrapper">
    <Navigation />
        <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
    <confirmPopup v-if='popupwindow==true' :title="titlepass" @cancelClick="popupwindow=false" @confirmClick="deletecourse(idpass)" />
    <div class="container-default-1209px w-container">
        <div data-w-id="4dfb3ae6-c85f-5256-cad4-afe060384278" style="" class="divider"></div>
    </div>
    <div id="Courses" style="" class="section latest-courses ">
        <div class="container-default-1209px w-container">
            <div class="latest-courses-wrapper">
                <h2 style="" class="title latest-courses flex flex-col gap-4 lg:flex-row items-center justify-between">Created Courses

                     <router-link :to="{name:'AddCourse'}" class="button-secondary w-button float-right">Add Courses</router-link>
                <router-link :to="{name:'NewCourse'}" class="button-secondary w-button float-right ">Add Courses with AI</router-link>

                </h2>
                <div class="w-dyn-list">
                    <div v-if="this.courselist" role="list" class="courses-grid ">
                        <div v-for="(course, index) in this.courselist" :key="course"  style="height:528px" role="listitem" class="course-card-wrapper w-dyn-item w-80 md:w-96 relative">
                            <div :id="index" class="rounded-lg shadow-lg w-80 md:w-96" style="height:550px">
                            <router-link :to="{name:'preview',params:{courseid:course.id}}" class="absolute top-4 right-6 z-50">
                                <button class=" bg-white border px-2 py-0.5 text-sm text-black rounded-lg hover:text-blue-900">Preview</button>
                            </router-link>
                                <a href="#" class="card-image-link w-inline-block rounded-lg">
                                    <img :src="course.thumbnail" alt="" style="overflow:hidden;height:208px" class="image course w-80 md:w-96"></a>
                                <div class="card-content" style="overflow: auto;height: 363px;">
                                    <a href="#" class="card-title-link w-inline-block">
                                        <h3 class="text-lg 2xl:text-xl course" :title='course.title'>{{course.title.slice(0,80)}}
                                        <!-- <span id="coursetitle">...</span> -->
                                        <!-- <span id="titlemore" title="{{course.title}}" class="hidden">{{course.title.slice(80)}}</span> -->
                                        </h3>
                                        <!-- <a id="titlebutton" class="duration-300 text-sm">Read more</a> -->
                                    </a>
                                    <p class="text-base">{{course.short_description.slice(0,80)}} 
                                    <span id="dots">...</span>
                                    <span id="more" class="hidden">{{course.short_description.slice(80)}}</span>
                                    </p>
                                    <a id="button" class="readmorecutton  duration-300 text-sm">Read more</a>


                                    <div class="divider course-card"></div>
                                    <div class="course-card-details-wrapper">
                                        <div class="flex gap-1 items-center justify-between ">
                                            <router-link :to="{name:'UpdateLesson',params:{courseId:course.id}}" class="border px-2 rounded flex gap-1 border-green-400 text-sm items-center py-1 hover:bg-green-100 ">
                                                <img src="../assets/Addbutton.svg" alt="" class="h-6 w-6 cursor-pointer" title="Add Lesson">
                                                Add section
                                            </router-link>
                                            <router-link :to="{name:'EditCourse',params:{course_id:course.id}}" class="border border-gray-200 px-2 rounded flex gap-1 text-sm items-center py-1 hover:bg-gray-100 ">
                                                <img src="../assets/edit.svg" alt="" class="h-6 w-6 cursor-pointer" title="Edit Course">
                                                Edit Course
                                            </router-link>
                                            <button class="border border-red-400 px-2 rounded flex gap-1 text-sm items-center py-1 hover:bg-red-100 " @click="titlepass=course.title;popupwindow=true;idpass=course.id">
                                                <!-- <img src="../../assets/trash.svg" alt="" class="h-6 w-6 cursor-pointer" title="Delete Course" >  -->
                                                <font-awesome-icon class="text-base text-red-400 self-center h-6 w-6" :icon="['fas','trash-alt']" />
                                                Delete 
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="card empty-state w-dyn-empty">
                        <div class="empty-state-text">There are no courses available yet.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-default-1209px w-container">
        <div data-w-id="4dfb3ae6-c85f-5256-cad4-afe0603842ac" style="" class="divider"></div>
    </div>

    <div class="container-default-1209px w-container">
        <div class="divider"></div>
    </div>
    <Footer />

</div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";
import FlashMessage from "@/components/flashMessage.vue";
import confirmPopup from "@/components/confirmPopup.vue";
import axios from "axios";
import $ from "jquery"
import {
    mapGetters
} from "vuex"
export default {
    components: {
        Navigation,
        Footer,
        FlashMessage,
        confirmPopup,
    },
    data() {
        return {
            courselist: "",
            instructorInfo: '',
            messages: {
                text: "",
                type: 'null',
            },
            titlepass: "",
            popupwindow: false,
            idpass: "",
        }
    },

    computed: mapGetters(['userInfo']),

    async created() {
        await axios.get(`${process.env.VUE_APP_API}/instructor/?Key=${this.userInfo.token_key}`)
            .then(response => {

                this.instructorInfo = response.data
                console.log(this.instructorInfo, "info")
            })
        await axios.get(`${process.env.VUE_APP_API}/courses/?instructor=${this.instructorInfo.id}`)
            .then(response => {
                if (response.data.message == "No courses details") {
                    console.log("Null")
                } else {
                    this.courselist = response.data.data
                    this.courselist.forEach(element => {
                        element.displayprice = new Intl.NumberFormat('hi-IN', {
                            style: 'currency',
                            currency: 'INR',
                            maximumFractionDigits: 0,
                        }).format(element.price);
                    });
                }
            })
    },
    methods:{
        async deletecourse(data) {
            await axios.delete(`${process.env.VUE_APP_API}/courseupdate/${data}/?Key=${this.userInfo.token_key}`)
                .then(response => {
                    this.res = response
                    if (this.res.statusText == 'No Content') {
                        this.messages = {
                            text: "Deleted successfully",
                            type: 'success',
                        }
                        console.log("successfull")
                    } else {
                        this.messages = {
                            text: " Event Faild",
                            type: 'error',
                        }
                    }
                })
            this.popupwindow = false

            setTimeout(() => {
                this.$router.go(0);
                this.messages = {
                    text: "",
                    type: 'null',
                }
            }, 2000)
        },
    },
   mounted() {

    // redirectToDashboard(courses) {
    //     this.$router.push({
    //         name: "InstructorStudents",
    //         params: {
    //             course_id: courses.id,
    //             instructor: courses.instructor,
    //             course_name: courses.title
    //         }
    //     })
    // },    
    // setTimeout(() => {
    //   var dots = document.getElementById("dots");
    //   var moreText = document.getElementById("more");
    //   var button = document.querySelector("#button");

    //   button.addEventListener("click", function () {
    //     if (dots.classList.contains("hidden")) {
    //       // Show the dots
    //       dots.classList.remove("hidden");

    //       // Hide the more text
    //       moreText.classList.add("hidden");

    //       // change text of the button
    //       button.innerHTML = "Read more";
    //     } else {
    //       // Hide the dots
    //       dots.classList.add("hidden");

    //       // hide the more text
    //       moreText.classList.remove("hidden");

    //       // change text of the button
    //       button.innerHTML = "Read less";
    //     }
    //   });
    // }, 3000);

    $(document).ready(function(){
        setTimeout(function(){
        var button = $(".readmorecutton");
        button.on('click',function(e){
        var readmorecutton = $(e.target);
        var target = $(e.target).parents('.card');
        var dots = target.find('#dots');
        var more = target.find('#more');
        if (dots.hasClass('hidden')){
            dots.removeClass('hidden');
            more.addClass('hidden');
            readmorecutton.text("Read more");
        }
        else {
            dots.addClass("hidden");
            more.removeClass("hidden");
            readmorecutton.text("Read less");
        }
        });
        }, 1000);
       
    });
  },   
};


</script>
