<template>

  <div class="min-h-screen relative">

    <Navigation />

    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />

    <div class="min-h-screen w-full container-default-1209px w-container">
      <p class="my-3">All Communities</p>

      <!-- Course Details -->
      <div
        class="flex flex-wrap justify-start w-full h-auto overflow-y-auto py-4 gap-4"
      >
        <div
          v-for="course in this.courseDetails"
          :key="course"
          class="h-48 w-80 shadow-lg card1"
        >
        <!-- class="h-48 w-80 shadow-lg card1" -->
          <router-link :to="{name:'chatStudent', params: {slug: this.userInfo.slug_id, course_id: course.id,instructor_id: course.instructor, course_name: course.title,}}"
          >

            <!-- <div
              class="relative flex justify-center items-center"
              style="height: 75%"
            >
              <img
                v-if="course.thumbnail"
                class="object-cover object-center h-full w-full rounded-t-md"
                :src="course.thumbnail"
                alt=""
              />
              <img
                v-else
                class="object-cover object-center h-24 w-52 rounded"
                src="@/assets/coding.svg"
                alt=""
              />
           
            </div> -->

            <div
              class=" text-center flex flex-col justify-center shadow-lg rounded-b-md h-full"
             
            > 

              <!-- <img
                  class="object-cover object-center !h-[100px] !h-[100px] rounded"
                  src="@/assets/coding.svg"
                  alt=""
                /> -->
         
              <p class="text-sm text-gray-900 px-4 truncate mb-0">{{ course.title }}</p> 

              <!-- <router-link :to="{name:'chatStudent', params: {slug: this.userInfo.slug_id, course_id: course.id,instructor_id: course.instructor, course_name: course.title,}}">Course Dashboard</router-link>  -->

      

            </div> 
          </router-link> 

        
          <!-- <router-link :to="{name:'coursestep', params: {slug: this.userInfo.slug_id, course_id: course.id,instructor_id: course.instructor, course_name: course.title,}}" class="button-primary w-button">Course Dashboard</router-link> -->


          <!-- <router-link :to="{name:'coursestep', params: {slug: this.userInfo.slug_id,course_id: this.courselist[0].id,instructor_id: this.courseDetailInfo.instructor_id,course_name: this.courselist[0].title,}}" class="button-primary w-button">Course Dashboard</router-link> -->

          <!-- <router-link>

            <div
              class="relative flex justify-center items-center"
              style="height: 75%"
            >
              <img
                v-if="course.thumbnail"
                class="object-cover object-center h-full w-full rounded-t-md"
                :src="course.thumbnail"
                alt=""
              />
              <img
                v-else
                class="object-cover object-center h-24 w-52 rounded"
                src="@/assets/coding.svg"
                alt=""
              />
           
            </div>

            <div
              class="primary-color text-center flex flex-col justify-center shadow-lg rounded-b-md"
              style="height: 25%"
            >
              <p class="text-sm text-white truncate mb-0">{{ course.title }}</p>
            </div>

          </router-link> -->

        </div>
      </div>
  
    </div>


  </div>
</template>

<script>
import axios from "axios";
import FlashMessage from "@/components/flashMessage.vue";
import { mapGetters } from "vuex";
import Navigation from "@/components/Navigation.vue";
export default {
  data() {
    return {
      profileInfo: null,
      popUp: false,
      updateDetails: null,
      selectedFile: null,
      tnxDetails: "",
      profile: false,
      messages: {
        text: "",
        type: "null",
      },
      selectedcontent: "Courses",
      courseDetails: null,
      boughtcourse: null,
    };
  },

  components: {
    Navigation,
    FlashMessage,
  },


  computed: mapGetters(["userInfo"]),

  async created() {
    await this.openProcess();
    let tempvar_courselist = [];
    await axios
      .get(`${process.env.VUE_APP_API}/courses/?format=json`)
      .then((response) => (this.courseDetails = response.data.data));
    await axios
      .get(
        `${process.env.VUE_APP_API}/studentsbuycoursedetails/?Key=${this.userInfo.token_key}`
      )
      .then((response) => {
        this.boughtcourse = response.data.data;
      });
    this.boughtcourse.forEach((element) => {
      tempvar_courselist.push(element.course_id);
    });

    this.courseDetails = this.courseDetails.filter((course) => {
      if (tempvar_courselist.includes(course.id)) {
        return course;
      }
    });
  },

  methods: {
    async imageHandles() {
      this.profile = false;
      let headerprofile = new FormData();
      headerprofile.append("id", this.userInfo.user.id);
      headerprofile.append(
        "profile",
        document.querySelector("#image_uploads").files[0]
      );

      await axios
        .put(
          `${process.env.VUE_APP_API}/upload_photo/?Key=${this.userInfo.token_key}`,
          headerprofile
        )
        .then((response) => {
          if (response.data.message == "profile Data Updated successfully") {
            this.messages = {
              text: "Profile Uploaded successfully",
              type: "success",
            };
          } else {
            this.messages = {
              text: " Event Faild",
              type: "error",
            };
          }
        })
        .catch((error) => {
          this.messages = {
            text: error.response.data.error,
            type: "error",
          };
        });

      setTimeout(() => {
        this.messages = {
          text: "",
          type: "null",
        };
        this.openProcess();
      }, 3000);
    },

    onFileChanged(event) {
      this.selectedFile = event.target.files[0];
    },

    async onUpload() {
      // upload file
      await axios.post("my-domain.com/file-upload", this.selectedFile);
    },

    async openProcess() {
      axios
        .get(
          `${process.env.VUE_APP_API}/paymentlist/?Key=${this.userInfo.token_key}`
        )
        .then((res) => {
          console.log(res);
          this.tnxDetails = res.data;
        });
      let token = this.userInfo.token_key;
      let slug_id = this.userInfo.slug_id;

      await axios
        .get(
          `${process.env.VUE_APP_API}/profile_detail/${slug_id}?Key=${token}&format=json`
        )
        .then((response) => (this.profileInfo = response.data));
    },

    async ProfileEdit() {
      let details_id = this.profileInfo.data.id;
      let token = this.userInfo.token_key;
      this.popUp = true;

      await axios
        .get(
          `${process.env.VUE_APP_API}/profile_update/${details_id}?Key=${token}&format=json`
        )
        .then((response) => (this.updateDetails = response.data));
    },

    async SubmitProfile() {
      let details_id = this.profileInfo.data.id;
      let token = this.userInfo.token_key;
      let update = this.updateDetails;
      this.popUp = false;
      // console.log("updateDetails",updateDetails)

      await axios
        .put(
          `${process.env.VUE_APP_API}/profile_update/${details_id}/?Key=${token}&format=json`,
          update
        )
        .then((response) => {
          if (response.data.message == "Profile updated successfully") {
            this.messages = {
              text: "Profile Uploaded successfully",
              type: "success",
            };
          } else {
            this.messages = {
              text: " Event Faild",
              type: "error",
            };
          }
        });

      setTimeout(() => {
        this.messages = {
          text: "",
          type: "null",
        };
        this.openProcess();
      }, 3000);
    },
  },
};
</script>

<style scoped>
#image_uploads {
  background-color: white;
  border-radius: 5px;
  box-shadow: 2px 2px 4px black;
  width: 350px;
  outline: none;
}
</style>
