<template>
  <div>
    <canvas id="myChart" width="300" height="300"></canvas>
  </div>
</template>

<script>
export default {
  mounted() {
    this.createChart();
  },
  methods: {
    createChart() {
      const ctx = document.getElementById("myChart").getContext("2d");
      new Chart(ctx, {
        type: "bar",
        data: {
          labels: [
            "Maths",
            "Computer Science",
            "Physics",
            "CA",
          ],
          datasets: [
            {
              label: "Assignments Completed",
              data: [65, 59, 80, 81, 56, 55, 40],
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              borderColor: "rgba(75, 192, 192, 1)",
              borderWidth: 1,
            //   barThickness: 10,
            },
          ],
        },
        options: {
          responsive: true,
          indexAxis: "y",
          scales: {
            x: {
              beginAtZero: true,
              ticks: {
                stepSize: 10,
              },
            },
          },
        },
      });
    },
  },
};
</script>
