<template>
  <div class="min-h-screen w-full container-default-1209px w-container">
    <div class="flex gap-6 justify-end py-5">
      <div
        class="mb-1 w-16 cursor-pointer rounded-lg flex border justify-center gap-2 items-center p-1 bg-white text-sm text-gray-700 hover:text-teal-500"
        @click="genaicourse = true"
      >
        Edit
      </div>
      <div
        class="mb-1 cursor-pointer w-36 rounded-lg flex border justify-center gap-2 items-center p-1 bg-white text-sm text-gray-700 hover:text-teal-500"
        @click="convertcourse"
      >
        Course converter
      </div>

      <div @click="goBack" class="">
        <div
          class="mb-1 cursor-pointer rounded-lg flex border justify-center w-24 gap-2 items-center py-1 bg-white"
        >
          <div class="text-sm text-gray-700 hover:text-teal-500" title="back">
            back
          </div>
          <div class="flex items-center">
            <img
              src="https://img.icons8.com/external-simple-solid-edt.graphics/15/undefined/external-Back-arrows-simple-solid-edt.graphics-2.png"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="genaicourse">
      <form @submit.prevent="genaicourseedit" class="border p-5">
        <div class="col-start-1 col-end-4">
          <label for="description">
            Description<span class="text-red-400">*</span>
          </label>

          <div class="h-[40%] w-full">
            <v-md-editor
              id="sample"
              v-model="markdown"
              left-toolbar="undo redo clear| tip todo-list | h bold italic strikethrough quote | ul ol table hr | link image code | save"
              :source="markdown"
              height=""
              class="col-span-2 mt-4 border border-gray-300 rounded-md w-full"
            >
            </v-md-editor>
          </div>
        </div>
        <div class="flex justify-between items-center py-2 lg:py-4">
          <div>
            <p class="text-green-400 text-xl" v-if="message">{{ message }}</p>
          </div>
          <div class="flex justify-end">
            <button class="px-4 py-2 gradient-btn rounded-lg" type="sumbit">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>

    <div class="overflow-y-auto" v-else>
      <markdown-preview v-if="popupData2.course" :source="popupData2.course" />
    </div>

    <!-- convert popup -->
    <div
      v-if="convertpopup"
      class="w-full h-full mx-auto fixed top-0 left-0 bottom-0 bg-black bg-opacity-75 p-4 flex items-center"
      style="z-index: 5000"
    >
      <div
        class="flex justify-center items-center bg-gray-50 mx-auto w-full lg:max-w-4xl 2xl:max-w-5xl rounded-lg relative !z-999 p-5 pt-5 text-3xl font-bold"
      >
        <div class="p-8 w-full text-lg">
          <div class="flex justify-end h-8 lg:h-9">
            <div
              @click="convertpopup = false"
              class="border cursor-pointer flex rounded-lg bg-white"
            >
              <h1
                class="px-1 md:px-2 py-1 text-sm md:text-base hover:text-red-500"
              >
                Close
              </h1>
              <img
                class="h-5 w-5 md:h-8 md:w-8 my-auto lg:my-0"
                src="@/assets/closebutton.svg"
              />
            </div>
          </div>
          <div class="w-full bg-gray-50 rounded-md border shadow-lg'">
            <form
              ref="addCourseRef"
              @submit.prevent="createCourse"
              class="w-full lg:p-4 overflow-scroll"
              style="max-height: 600px"
            >
              <h1 class="text-left py-2 text-3xl font-semibold text-gray-600">
                Course
              </h1>
              <div class="parent">
                <div class="">
                  <label for="title">
                    Title<span class="text-red-400">*</span>
                  </label>
                  <input
                    id="title"
                    type="text"
                    required
                    v-model="conversion_title"
                    class="border border-gray-300 rounded-md text-sm w-full p-2"
                  />
                </div>

                <div class="col-start-1 col-end-4">
                  <label for="description">
                    Description<span class="text-red-400">*</span>
                  </label>

                  <div class="h-[40%] w-full">
                    <!-- <textarea
                    id="description_markdown"
                      v-model="markdown"
                      
                      class="min-h-screen border rounded-l-md border-gray-300"
                    />
                    <markdown-preview
                      :source="markdown"
                      id="right_markdown"
                     
                      class="h-screen overflow-y-auto border rounded-r-md border-gray-300 w-full"
                    ></markdown-preview>  -->
                    <v-md-editor
                      id="sample"
                      v-model="markdown"
                      left-toolbar="undo redo clear| tip todo-list | h bold italic strikethrough quote | ul ol table hr | link image code | save"
                      :source="markdown"
                      height="700px"
                      class="col-span-2 mt-4 border border-gray-300 rounded-md w-full"
                    >
                    </v-md-editor>
                  </div>
                </div>

                <div class="">
                  <label for="thumbnail"> Thumbnail </label>
                  <input
                    id="thumbnail"
                    type="file"
                    @change="handleFileUpload"
                    class="border border-gray-300 rounded-md text-sm w-full p-2"
                  />
                </div>

                <div class="flex items-center gap-2">
                  <label for="checkbox">Published</label>
                  <input type="checkbox" id="Published" v-model="isPublished" />
                </div>
              </div>
              <div class="flex justify-between items-center py-2 lg:py-4">
                <div>
                  <p class="text-green-400 text-xl" v-if="message">
                    {{ message }}
                  </p>
                </div>
                <div class="flex justify-end">
                  <button type="submit" class="gradient-btn mt-4">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- popup edit -->
    <div
      v-if="submitpopup"
      class="w-full h-full mx-auto fixed top-0 left-0 bg-black bg-opacity-75 p-4 flex items-center"
      style="z-index: 5000"
    >
      <div
        class="relative w-auto mx-auto rounded-lg bg-white p-12 text-gray-700 text-center shadow-xl"
      >
        <div
          @click="submitpopup = false"
          class="absolute right-4 top-0 text-6xl text-right font-light primary-text-color cursor-lg cursor-pointer"
        >
          &times;
        </div>

        <h2 class="p-4 text-lg font-semibold">{{ message }}</h2>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import MarkdownPreview from "@uivjs/vue-markdown-preview";
import "@uivjs/vue-markdown-preview/markdown.css";

export default {
  name: "CoursePreview",
  props: ["course_id"],

  data() {
    return {
      popupData2: [],
      convertpopup: false,
      promt_id: "",
      prompt_value: [],
      conversion_title: "",
      markdown: "",
      thumbnail: null,
      isPublished: "",
      submitpopup: false,
      genaicourse: false,
      genicourseid: "",
      courseedit: [],
      message: "",
    };
  },
  computed: mapGetters(["userInfo", "instructorInfo"]),
  components: {
    MarkdownPreview,
  },
  created() {
    this.getGemini();
    console.log("course_id", this.course_id);
  },
  methods: {
    goBack() {
      this.$router.push({
        name: "instructorCourse",
        query: { activeTab: "coursepromt" },
      });
    },
    getGemini() {
      axios
        .get(
          `${process.env.VUE_APP_API}/gemini/list/?Key=${this.userInfo.token_key}&courseprompt=${this.course_id}`
        )
        .then((response) => {
          if (response.status == 200) {
            console.log("id////850", response.data);
            this.popupData2 = response.data.data[0];
            this.promt_id = this.popupData2.courseprompt;
            this.markdown = response.data.data[0].course;
            this.genicourseid = response.data.data[0].id;
            console.log("this.promt_id", this.promt_id);
            console.log(" this.popupData2", this.popupData2);
            console.log("id", response.data.data[0].id);
          } else {
            console.log("error on api");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    convertcourse() {
      this.convertpopup = true;
      this.promptList();
    },
    promptList() {
      axios
        .get(
          `${process.env.VUE_APP_API}/courseprompt/get/?id=${this.promt_id}&Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          if (response.status == 200) {
            this.prompt_value = response.data.data;
            console.log("prompt_value", this.prompt_value);
            this.conversion_title = response.data.data[0].title;
            console.log("conversion_title", this.conversion_title);
          } else {
            console.log("error on api");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    genaicourseedit() {
      const genaidata = {
        course: this.markdown,
      };
      console.log("genaidata", genaidata);
      console.log("genaidata", this.genicourseid);

      axios
        .patch(
          `${process.env.VUE_APP_API}/genaicourse/update-course/${this.genicourseid}/`,
          genaidata
        )
        .then((response) => {
          console.log("datashow", response);
          if (response.status == 200) {
            this.popupData2 = response.data;
            this.message = response.data.message;
            setTimeout(() => {
              this.message = "";
              this.genaicourse = false;
            }, 3000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleFileUpload(event) {
      this.thumbnail = event.target.files[0];
    },
    async createCourse() {
      const formData = new FormData();
      formData.append("course_title_data", this.conversion_title);
      formData.append("category", this.markdown);
      formData.append("thumbnail", this.thumbnail, this.thumbnail.name);
      formData.append("status", this.isPublished);
      console.log("formData", formData);
      console.log("promtid", this.promt_id);

      await axios
        .post(
          `${process.env.VUE_APP_API}/upload-course-content/${this.promt_id}/`,
          formData
        )

        .then((response) => {
          console.log("response", response);
          if (response.status == 201) {
            this.newcourseid = response.data;
            console.log("upload?////", this.newcourseid);
            this.message = response.data.message;
            console.log("message", this.message);

            setTimeout(() => {
              this.message = "";
              this.convertpopup = false;
            }, 3000);
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
      // this.convertpopup = false;
      // this.submitpopup = true;
      // setTimeout(() => {
      //   this.submitpopup = false;
      // }, 3000);
    },
  },
};
</script>

<style></style>
