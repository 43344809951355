<template lang="">
    <Navigation />
<div class="h-screen flex w-full relative ">
    <confirmPopup v-if='popupwindow==true' :title="titlepass" @cancelClick="popupwindow=false" @confirmClick="deleteEvents(idpass)" />
    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
    <!-- New Vue-cal  -->
    <internLaftNav actived='events' />
    <div class="flex h-full w-full overflow-y-auto" style="scrollbar-color:gray white">
        <div class="h-full w-full">
            <div class="relative">
                <div>
                    <button @click="this.addevent=true" class="absolute right-0 top-20 md:top-1/3 mr-2 mt-2 text-green-600 hover:text-green-800 hover:bg-gray-100 shadow-lg bg-white rounded w-24 z-50">Add Event</button>
                </div>
            </div>
            <vue-cal id="vueCal" small ref="vuecal" v-model:active-view="activeView" events-on-month-view="short" :disable-views="['years']" :events="values" :on-event-click="onEventClick" class="">
            </vue-cal>
        </div>
    </div>

    <!-- popup details -->
    <div v-if="eventInfo" class="absolute w-full h-full z-40 flex items-center bg-black bg-opacity-50">
        <div id="eventdetailsPopUp" class="mx-auto border bg-white flex flex-col items-center rounded-lg relative">
            <div class="flex w-full justify-between bg-teal-700 py-3 px-4 items-center border text-base font-semibold text-white rounded-t-lg">
                <div class="text-base capitalize w-2/3">{{this.eventInfoData.title}}</div>
                <img class='h-6 w-6 cursor-pointer' src="../../assets/closebutton.svg" Title="Close" @click="eventInfo= false" />
            </div>
            <div class="text-lg font-semibold text-teal-700 pt-4">
                    {{ eventInfoData.start && eventInfoData.start.format('DD/MM/YYYY') }}
                    ( {{ eventInfoData.start && eventInfoData.start.formatTime() }} - 
                    {{ eventInfoData.end && eventInfoData.end.formatTime() }}
                    ) 
            </div>
            <div class="flex flex-col bg-white w-full px-4 py-4 justify-center">
                <div class="text-lg font-semibold text-gray-600">Event Details</div>
                <p v-html="eventInfoData.contentFull" class="text-sm text-gray-600" />
            </div>
            <div class="absolute flex gap-4 right-4 bottom-4">
                <div class="w-24 flex flex-row justify-center cursor-pointer border hover:border-teal-500 rounded bg-white hover:bg-teal-500 hover:text-white" @click="updatevalues=eventInfoData.event;popUp=true">
                    <div class="py-1 text-sm md:text-base">Edit </div>
                </div>
                <div class="w-24 flex flex-row justify-center cursor-pointer border hover:border-red-500 rounded bg-white hover:bg-red-500 hover:text-white" @click="titlepass=eventInfoData.title;popupwindow=true;idpass=eventInfoData.id">
                    <div class="px-1 md:px-2 py-1 text-sm md:text-base " >Remove </div>
                </div>
                    <!-- <img src="../../assets/edit.svg" alt="" class="h-6 w-6 cursor-pointer" title="Edit" @click="updatevalues=eventInfoData.event;popUp=true">
                    <img src="../../assets/trash.svg" alt="" class="h-6 w-6 cursor-pointer" title="Delete" @click="titlepass=eventInfoData.title;popupwindow=true;idpass=eventInfoData.id"> -->
            </div>
        </div>
    </div>
    <div v-if="addevent==true" class="absolute w-full h-screen p-4 bg-black z-50 flex items-center bg-opacity-50">
        <div class=" w-full md:w-4/6 lg:w-3/6 xl:w-2/6 relative mx-auto border py-2 px-2 bg-white rounded-lg">
            <div class="text-sky-700 text-2xl font-bold ">Create Events</div>
            <form ref="ScheduleEvent" @submit.prevent="scheduledate" class="flex flex-col gap-4 w-full px-2">
                <div class="flex flex-col gap-2">
                    <label for="meetingTitle">Meeting Title <span class="text-red-400">*</span></label>
                    <input id="meetingTitle" type="text" class="border py-1 border-gray-400 rounded" required />
                </div>
                <div class="flex flex-col gap-2">
                    <label for="meetingDate">Meeting Date <span class="text-red-400">*</span></label>
                    <input id="meetingDate" type="datetime-local" class="border py-1 border-gray-400 rounded" required>
                </div>
                <div class='flex flex-col gap-2'>
                    <label for="duration">
                        Sesson duration
                    </label>
                    <input id="duration" type="number" class="border py-1 border-gray-400 rounded" min="1" />
                </div>
                <div class='flex flex-col gap-2'>
                    <label for="studentLimit">
                        Student Limit
                    </label>
                    <input id="studentLimit" type="number" class="border py-1 border-gray-400 rounded" min="1" />
                </div>
                <div class="flex flex-col gap-2">
                    <label for="meetingUrl">Meeting Url <span class="text-red-400">*</span></label>
                    <button type="button" id="urlGenerator" class="gradient-btn w-52" @click="generateUrl()">Generate Url</button>
                    <input id="meetingUrl" type="text" class="hidden border py-1 border-gray-400 rounded" v-model="generatedUrl" />
                </div>
                <div>
                    <button type="submit" class="gradient-btn">Submit</button>
                </div>
            </form>
            <div class='flex absolute right-10 select-none rounded-lg border top-3 md:top-2 hover:text-red-400 items-center cursor-pointer' @click="this.addevent=false;">
                <div class="px-1 md:px-2 py-1 text-sm md:text-base">Close </div><img class='h-5 w-5 md:h-8 md:w-8' src="../../assets/closebutton.svg" />
            </div>
        </div>
    </div>
    <div v-if="popUp" class="flex flex-col gap-2 w-full h-screen bg-black z-50 items-center bg-opacity-50 justify-center absolute">
        <div id="eventEdit" class='w-11/12 lg:w-3/6 mx-auto relative bg-white flex flex-col justify-center px-4 rounded'>
            <div class='flex absolute right-10 select-none rounded-lg border top-2 hover:text-red-400 items-center cursor-pointer' @click="popUp=false">
                <div class="px-1 md:px-2 py-1 text-sm md:text-base">Close </div><img class='h-5 w-5 md:h-8 md:w-8' src="../../assets/closebutton.svg" />
            </div>

            <div class="flex flex-col gap-2">
                <label for="meetingTitle">Meeting Title <span class="text-red-400">*</span></label>
                <input id="meetingTitle" type="text" class="border py-1 border-gray-400 rounded" v-model="updatevalues.title" required />
            </div>
            <div class="flex flex-col gap-2">
                <label for="meetingDate">Meeting Date <span class="text-red-400">*</span></label>
                <input id="meetingDate" type="datetime-local" class="border py-1 border-gray-400 rounded" v-model="updatevalues.date" required>
            </div>
            <div class='flex flex-col gap-2'>
                <label for="duration">
                    Sesson duration
                </label>
                <input id="duration" type="number" class="border py-1 border-gray-400 rounded" min="1" v-model="updatevalues.duration" />
            </div>
            <div class='flex flex-col gap-2'>
                <label for="studentLimit">
                    Student Limit
                </label>
                <input id="studentLimit" type="number" class="border py-1 border-gray-400 rounded" min="1" v-model="updatevalues.count" />
            </div>
            <div class="flex flex-col gap-2">
                <label for="meetingUrl">Meeting Url <span class="text-red-400">*</span></label>
                <button type="button" id="Editurl" class="gradient-btn w-52" @click="generateUrlEdit()">Generate Url</button>
                <input id="meetingUrledit" type="text" class="hidden border py-1 border-gray-400 rounded" v-model="updatevalues.url" required />
            </div>
            <div>
                <button @click="editEvents()" type="submit" class="gradient-btn mt-2">Updated</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios"
import {
    mapGetters
} from "vuex";

import Navigation from "@/components/Navigation.vue"
import internLaftNav from "@/views/organization/leftNavIntern.vue"
import FlashMessage from "@/components/flashMessage.vue";
import confirmPopup from "@/components/confirmPopup.vue";
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
export default {
    data() {

        return {
            attributes: [],
            organizationInfo: null,
            calendarInfo: null,
            targetDate: new Date(),
            scheduleDetails: null,
            popUp: false,
            updatevalues: null,
            messages: {
                text: "",
                type: 'null',
            },
            titlepass: "",
            popupwindow: false,
            idpass: "",
            addevent: false,
            activeView: 'month',
            eventInfo: false,
            eventInfoData: "",
            values: [],
            generatedUrl: "",

        }
    },
    computed: {
        ...mapGetters(["userInfo"]),

    },

    components: {
        Navigation,
        internLaftNav,
        FlashMessage,
        confirmPopup,
        VueCal
    },
    async mounted() {
        await this.getinfos()
    },

    methods: {

        onEventClick(event, e) {
            this.eventInfo = true
            this.eventInfoData = event
        },

        async getinfos() {
            console.log(this.userInfo, 'userinfo')
            await axios.get(`${process.env.VUE_APP_API}/organization_detail/?Key=${this.userInfo.token_key}`)
                .then(Response => {
                    console.log(this.organizationInfo = Response.data.data)
                })

            await axios.get(`${process.env.VUE_APP_API}/calendar/view/?Key=${this.userInfo.token_key}`)
                .then(Response => {
                    if (Object.keys(Response.data) == "error") {
                        this.values = []
                        console.log("Null")
                    } else {

                        this.calendarInfo = Response.data.data
                        console.log(this.calendarInfo, "calendar")
                        this.values = []
                        this.calendarInfo.forEach(element => {
                            // adding 30mits to the date
                            let startdate = new Date(element.date)
                            let enddate = new Date(startdate)
                            enddate = new Date(enddate.setMinutes(startdate.getMinutes() + 30))

                            // reasigning the data to vue-cal event
                            let tempvar = {
                                start: element.date,
                                end: enddate,
                                title: element.title,
                                class: 'Progressive',
                                contentFull: `<div class="px-2"> <div class="flex gap-2"><span>Event Link :</span><span>${element.url}</span></div> 
                                         <div class="flex gap-2"><span>Event Duration :</span><span>${element.duration}</span></div>
                                           <div class="flex gap-2"><span>Event Student limit :</span> <span>${element.count}</span></div>
                                            <div class="flex gap-2"><span>Event Student Booked :</span> <span>${ element.studentlist==null ? 0 :  element.studentlist.length}</span></div> 
                                            </div>`,
                                id: element.id,
                                event: element
                            }
                            this.values.push(tempvar) // pushing the database event to the vue-cal
                        });
                    }

                })
        },

        async deleteEvents(id) {
            console.log('Delete Function')
            axios.delete(`${process.env.VUE_APP_API}/calendarupdated/${id}/?Key=${this.userInfo.token_key}`)
                .then(response => {
                    console.log(response, "Delete Data")
                    if (response.statusText == 'No Content') {
                        this.messages = {
                            text: "Event Deleted successfully",
                            type: 'success',
                        }
                    } else {
                        this.messages = {
                            text: " Event Faild",
                            type: 'error',
                        }
                    }
                })
            this.popupwindow = false
            this.eventInfo = false
            setTimeout(() => {
                this.messages = {
                    text: "",
                    type: 'null',
                }

                this.getinfos()

            }, 1500)
        },
        async editEvents() {
            console.log('Edit Function')

            let updatedatas = {
                "title": this.updatevalues.title,
                "organization_id": this.updatevalues.organization_id,
                "user": this.updatevalues.user,
                "status": this.updatevalues.status,
                "url": this.updatevalues.url,
                "date": this.updatevalues.date,
                "count": this.updatevalues.count,
                "duration": this.updatevalues.duration,
                "studentlist": null,
                "course_data": null
            }
            this.popUp = false
            axios.put(`${process.env.VUE_APP_API}/calendarupdated/${this.updatevalues.id}/?Key=${this.userInfo.token_key}`, updatedatas)
                .then(response => {
                    console.log(response)
                    if (response.statusText == 'OK') {
                        this.messages = {
                            text: "Event Updated successfully",
                            type: 'success',
                        }
                    } else {
                        this.messages = {
                            text: " Event Faild",
                            type: 'error',
                        }
                    }
                })

            this.updatevalues = null
            this.eventInfo = false
            this.getinfos()

            setTimeout(() => {
                this.messages = {
                    text: "",
                    type: 'null',
                }
            }, 1500)
        },
        
        generateUrl(){

            
            let title = document.getElementById("meetingTitle").value
            let durations = document.getElementById("duration").value
            let timedetails = document.getElementById("meetingDate").value
            timedetails = timedetails.replaceAll('T', ' ')
            let inputdatas = {
                            "topic":title,
                            "start_time":timedetails,
                            "agenda":title,
                            "duration":durations
                        }
            console.log(inputdatas)

            axios.post(`${process.env.VUE_APP_API}/zoom/?Key=${this.userInfo.token_key}`, inputdatas)
            .then(response=>{
                // console.log(response)
                this.generatedUrl = response.data.data.zoom_join_url
                // console.log(this.generatedUrl)
                document.getElementById("meetingUrl").classList.remove("hidden")
                document.getElementById("urlGenerator").classList.add("hidden")
            })
        },

        generateUrlEdit(){
            let title = this.updatevalues.title
            let durations = this.updatevalues.duration
            let timedetails = this.updatevalues.date
            timedetails = timedetails.replaceAll('T', ' ')
            let inputdatas = {
                            "topic":title,
                            "start_time":timedetails,
                            "agenda":title,
                            "duration":durations
                        }
            console.log(inputdatas)

            axios.post(`${process.env.VUE_APP_API}/zoom/?Key=${this.userInfo.token_key}`, inputdatas)
            .then(response=>{
                // console.log(response)
                this.updatevalues.url = response.data.data.zoom_join_url
                // console.log(this.generatedUrl)
                document.getElementById("meetingUrledit").classList.remove("hidden")
                document.getElementById("Editurl").classList.add("hidden")
            })
        },

        async scheduledate(el) {

            let Urls = el.target.elements.meetingUrl.value
            let timedetails = el.target.elements.meetingDate.value
            let studentlimit = el.target.elements.studentLimit.value
            let durations = el.target.elements.duration.value
            let title = el.target.elements.meetingTitle.value
            timedetails = timedetails.replaceAll('T', ' ')
            // console.log(timedetails)

            // let inputdatas = {
            //     "title": title,
            //     "organization_id": this.organizationInfo.id,
            //     "user": this.userInfo.Instructor_id,
            //     "status": true,
            //     "url": Urls,
            //     "date": timedetails,
            //     "count": studentlimit,
            //     "duration": durations,
            //     "studentlist": null,
            //     "course_data": null
            // }

            let inputdatas = {
                "title": title,
                "organization_id": this.userInfo.organizes_id,
                "user": this.userInfo.user.id,
                "status": true,
                "url": Urls,
                "date": timedetails,
                "count": studentlimit,
                "duration": durations,
                "studentlist": null,
                "course_data": parseInt(this.course_id)
            }

            console.log(inputdatas)

            await axios.post(`${process.env.VUE_APP_API}/calendar_new/?Key=${this.userInfo.token_key}`, inputdatas)
                .then(response => {
                    this.scheduleDetails = response.data
                })
            if (this.scheduleDetails.message == "your Calendar added successfully") {
                this.messages = {
                    text: "Event Added successfully",
                    type: 'success',
                }
            } else if (this.scheduleDetails == "is not a staff user") {
                this.messages = {
                    text: " Event Faild",
                    type: 'error',
                }
            }
            this.$refs.ScheduleEvent.reset();
            await this.getinfos()
            this.addevent = false
            setTimeout(() => {
                this.generatedUrl = ""
                this.messages = {
                    text: "",
                    type: 'null',
                }
            }, 1500)
        },
    },
}
</script>

<style>
.vuecal__event {
    color: #115E59;
    background-color: #F5F5F5;
    border: 1px dashed #ba0606;
    overflow: hidden;
}
.vuecal__cell-content{
     height: 180px;
}
.vuecal__event.Progressive {
    background-color: #0F766E;
    border-radius: 6px;
    border: 1px solid rgb(248, 247, 247);
    color: #fff;
}
.vuecal--short-events .vuecal__event-title {
    font-size: 0.8em;
    text-align: center;
}
.vuecal__cell-events{
    height: 180px;
    overflow: scroll;
    scrollbar-color: white transparent;
    cursor: pointer;
    
}

#vuecal {
    height: 93%
}

#eventdetailsPopUp {
    height: 25%;
    width: 25%
}

#eventEdit {
    height: 45%;

}

@media (min-width: 1024px) and (max-width: 1536px) {

    #vuecal {
        height: 90%
    }

    #eventdetailsPopUp {
        height: 35%;
        width: 45%
    }

    #eventEdit {
        height: 55%;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {

    #vuecal {
        height: 89%
    }

    #eventdetailsPopUp {
        height: 30%;
        width: 60%
    }

    #eventEdit {
        height: 50%;
    }
}

@media (min-width:300px) and (max-width: 768px) {

    #vuecal {
        height: 85%
    }

    #eventdetailsPopUp {
        height: 35%;
        width: 96%
    }

    #eventEdit {
        height: 65%;

    }
}
</style>
